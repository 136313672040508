import { useQueryClient } from '@tanstack/react-query';
import {
  CLINICAL_INFO_KEYS,
  ClinicalInfo,
  QueryKeys,
  ServerPatient,
  useIcon
} from '@viz/api';
import { mapClinicalInfoFormTitleToKey } from '@viz/api/src/patients/utils';
import { DialogProps, IconSizes, Loading } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { PropsWithChildren, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { API_STATIC_FILES_URL } from '../../../../../types';
import { PatientClinicalInfoForm } from '../types';
import {
  IconContainer,
  StyledAlert,
  StyledClinicalInfoDialog,
  StyledClinicalInfoForm,
  StyledClinicalInfoFormFieldLabel,
  StyledClinicalInfoFormFieldsContainer,
  StyledClinicalInfoFormHeader,
  StyledClinicalInfoFormSectionRowContainer,
  StyledInfoAlert,
  StyledSubmitLoadingButtonContainer,
  StyledTypography
} from './styles';
import { createRenderFunctionForInput } from './utils';

const DEFAULT_TIMEZONE_OFFSET = 1;

interface ClinicalInfoFormProps {
  form: PatientClinicalInfoForm;
  title: string;
  subtitle: string;
  isEditMode: boolean;
  onSubmit: (
    payload: ClinicalInfo.SubmitPatientClinicalInfoFormPayload,
    onSuccess: () => void
  ) => void;
  onClose: () => void;
  isSubmitting: boolean;
  testId?: string;
}

type CustomDialogProps = Omit<
  DialogProps,
  | 'onSubmit'
  | 'secondaryButtonProps'
  | 'primaryButtonProps'
  | 'withCloseIcon'
  | 'title'
  | 'subtitle'
>;

export type ClinicalInfoFormDialogProps = ClinicalInfoFormProps &
  Partial<CustomDialogProps>;

export const ClinicalInfoFormDialog = (props: ClinicalInfoFormDialogProps) => {
  const {
    form,
    title,
    isEditMode,
    subtitle,
    isSubmitting,
    onSubmit,
    onClose,
    open,
    ...rest
  } = props;
  const { control, handleSubmit, setValue, reset, getValues } =
    useForm<ClinicalInfo.SubmitPatientClinicalInfoFormPayload['fields']>();

  const queryClient = useQueryClient();
  const [isFormValid, setIsFormValid] = useState(true);

  const handleOnClickSubmit = () => {
    const isFormEmpty = Object.values(getValues()).every((v) => !v || v === '');
    if (!isEditMode && isFormEmpty) {
      setIsFormValid(false);
    } else {
      props.onSubmit(
        {
          fields: getValues(),
          form_id: form.formId,
          timezone_offset: DEFAULT_TIMEZONE_OFFSET
        },
        () => {
          queryClient.removeQueries({
            queryKey: [QueryKeys.GET_PATIENT_CLINICAL_INFO_FORM]
          });
        }
      );
    }
  };

  const resetForm = (): void => {
    reset();
    setIsFormValid(true);
  };

  const handleOnClose = (): void => {
    props.onClose();
    resetForm();
  };

  const iconURL = `${API_STATIC_FILES_URL}${form.formHighlightedIconUrl}`;
  const showLoading = !open || isSubmitting;

  return (
    <StyledClinicalInfoDialog
      role="clinical-info-form"
      maxWidth={'md'}
      withCloseIcon={true}
      open={Boolean(open)}
      title={title}
      subtitle={subtitle}
      primaryButtonProps={{
        content: (
          <StyledSubmitLoadingButtonContainer>
            {showLoading ? (
              <Loading
                size={IconSizes.MEDIUM}
                role="loader"
                color="secondary"
              />
            ) : (
              localize('submitAndShare')
            )}
          </StyledSubmitLoadingButtonContainer>
        ),
        disabled: showLoading,
        onClick: handleSubmit(handleOnClickSubmit)
      }}
      secondaryButtonProps={{
        content: <span>{localize('cancel')}</span>,
        onClick: handleOnClose
      }}
      disableBackdropClick
      onClose={handleOnClose}
      {...rest}
    >
      <StyledClinicalInfoForm id={form.formId}>
        <StyledClinicalInfoFormHeader>
          <ClinicalInfoFormIcon iconUrl={iconURL} />
          <StyledTypography fontSize="xl">
            {localize(mapClinicalInfoFormTitleToKey[form.formTitle])}
          </StyledTypography>
        </StyledClinicalInfoFormHeader>
        {!isFormValid && (
          <StyledAlert type="error" text={localize('formCannotBeLeftBlank')} />
        )}
        {isEditMode && (
          <StyledInfoAlert
            type="info"
            text={localize('enteringTextWillOverrideTheExistingText')}
          />
        )}
        <StyledClinicalInfoFormFieldsContainer>
          {form.sections.map((section) =>
            section.rows.map((row) => (
              <>
                {row.row_inputs.map((input) => (
                  <ClinicalFormSectionSectionRow
                    key={input.fieldId}
                    title={input.title}
                  >
                    <StyledClinicalInfoFormFieldLabel id={input.fieldId}>
                      {localize(CLINICAL_INFO_KEYS[input.title])}
                    </StyledClinicalInfoFormFieldLabel>
                    <Controller
                      key={input.fieldId}
                      control={control}
                      name={input.fieldId}
                      defaultValue={input.value}
                      render={({ field }) =>
                        createRenderFunctionForInput(input.type)(field, (e) => {
                          setValue(field.name, e.target.value);
                        })
                      }
                    />
                  </ClinicalFormSectionSectionRow>
                ))}
              </>
            ))
          )}
        </StyledClinicalInfoFormFieldsContainer>
      </StyledClinicalInfoForm>
    </StyledClinicalInfoDialog>
  );
};

const hcm_short_fields_titles: ServerPatient.ClinicalInfoItemTitle[] = [
  'SAM',
  'Mitral Regurgitation',
  'PW',
  'LVOT Gradient',
  'IVS',
  'EF'
];

const ClinicalFormSectionSectionRow = (
  props: PropsWithChildren<{ title: ServerPatient.ClinicalInfoItemTitle }>
) => {
  const isFullWidth = !hcm_short_fields_titles.includes(props.title);
  return (
    <StyledClinicalInfoFormSectionRowContainer fullWidth={isFullWidth}>
      {props.children}
    </StyledClinicalInfoFormSectionRowContainer>
  );
};

const ClinicalInfoFormIcon = ({ iconUrl }: { iconUrl: string }) => {
  const { data } = useIcon({ iconUrl });

  return (
    <>
      {data && (
        <IconContainer
          role="presentation"
          data-testid="clinical-info-form-icon"
          $size={IconSizes.X_MEDIUM}
          dangerouslySetInnerHTML={{ __html: data as string }}
        />
      )}
    </>
  );
};
