import { Ecg, Patient, Study } from '@viz/api';
import { Accordion, EmptyContent, IconTelescope } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { sortBy } from '@viz/utils';
import { useEffect, useMemo, useState } from 'react';

import { withVisibility } from '../../../../hocs';
import { StudyContainer, EcgStudyContainer } from './StudyContainers';
import StudyDetectionIcons from './StudyDetectionIcons';
import StudySubtitle from './StudySubtitle';
import { StyledPatientStudies, StyledPatientStudy } from './styles';

export type PatientStudiesProps = {
  patient: Patient;
  exams: Array<Study | Ecg>;
  activeStudyUid?: string;
  activeSeriesUid?: string;
};

const isEcg = (exam: Study | Ecg): boolean => 'ecgId' in exam;

const PatientStudies = ({
  patient,
  exams,
  activeStudyUid,
  activeSeriesUid
}: PatientStudiesProps): JSX.Element => {
  const [openStudyUid, setOpenStudyUid] = useState<string | null>(
    activeStudyUid ?? null
  );
  const sortedStudies = useMemo(
    () => sortBy(exams, 'lastArrivalTs', 'desc'),
    [exams]
  );

  useEffect(
    () =>
      setOpenStudyUid(
        activeStudyUid || sortedStudies[sortedStudies.length - 1]?.uid
      ),
    [activeStudyUid, sortedStudies]
  );

  return (
    <StyledPatientStudies container direction="column">
      {exams.length ? (
        sortedStudies.map((exam) => (
          <StyledPatientStudy item key={exam.uid}>
            <Accordion
              title={exam.description}
              subTitle={<StudySubtitle exam={exam} />}
              headerContent={
                <StudyDetectionIcons
                  isFailed={exam.isFailed}
                  highlightIcons={[...new Set(exam.highlightIcons)]}
                />
              }
              expandable={true}
              expanded={openStudyUid === exam.uid}
              isActive={activeStudyUid === exam.uid}
              onChange={(expanded) =>
                setOpenStudyUid(expanded ? exam.uid : null)
              }
              testId={`study-id-${exam.uid}`}
            >
              {isEcg(exam) ? (
                <EcgStudyContainer
                  patient={patient}
                  study={exam as Ecg}
                  activeStudyUid={activeStudyUid}
                />
              ) : (
                <StudyContainer
                  patient={patient}
                  study={exam as Study}
                  activeSeriesUid={activeSeriesUid}
                />
              )}
            </Accordion>
          </StyledPatientStudy>
        ))
      ) : (
        <EmptyContent title={localize('noStudies')} icon={<IconTelescope />} />
      )}
    </StyledPatientStudies>
  );
};

export default withVisibility(PatientStudies);
