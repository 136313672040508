import {
  ClinicalInfo as ClinicalInfoApi,
  ClinicalInfoItem,
  Patient,
  useGetPatientClinicalInfoForm,
  useSubmitPatientClinicalInfoForm
} from '@viz/api';
import {
  Alert,
  Button,
  CirclePlusIcon,
  IconSizes,
  Loading
} from '@viz/design-system';
import { localize } from '@viz/i18n';
import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { withVisibility } from '../../../../hocs';
import { AnalyticsEventName, useAnalyticsEvent } from '../../../../hooks';
import { toastStatusState } from '../../../../store';
import { ClinicalInfoFormDialog } from './ClinicalInfoFormDialog';
import { ClinicalInfoSection } from './ClinicalInfoSection';
import { ClinicalInfoSectionProps } from './ClinicalInfoSection/ClinicalInfoSection';
import { sectionTitleToItemKeys } from './constants';
import { useQueryWithLoadingThreshold } from './hooks';
import {
  ClinicalInfoEmptyStateLayout,
  ClinicalInfoTitle,
  StyledClinicalInfoLayout,
  StyledLoadingWrapper
} from './styles';
import { ClinicalInfoSectionTitle } from './types';

export interface ClinicalInfoProps {
  patient: Patient;
  enableEdit: boolean;
  onChangePatientClinicalInfo: () => void;
  patientTabId?: number;
  testId?: string;
}

export const ClinicalInfo = withVisibility((props: ClinicalInfoProps) => {
  const { sendEvent } = useAnalyticsEvent();
  const { enableEdit, patient } = props;

  const [showLoadingFormError, setShowLoadingFormError] = useState(false);
  const [isEdittingForm, setIsEdittingForm] = useState(false);
  const setToastStatus = useSetRecoilState(toastStatusState);
  const { mutate: submitPatientForm, isLoading: isSubmittingForm } =
    useQueryWithLoadingThreshold(useSubmitPatientClinicalInfoForm());

  const { data: form, isLoading: isLoadingForm } = useQueryWithLoadingThreshold(
    useGetPatientClinicalInfoForm(
      {
        patientId: patient.id,
        tabId: props.patientTabId
      },
      {
        enabled: isEdittingForm
      }
    )
  );

  const toggleIsEdittingForm = () => setIsEdittingForm(!isEdittingForm);

  const handleOnSubmit = (
    patientId: string,
    payload: ClinicalInfoApi.SubmitPatientClinicalInfoFormPayload,
    onSuccess: () => void
  ) => {
    submitPatientForm(
      {
        form_id: payload.form_id,
        timezone_offset: payload.timezone_offset,
        patientId: patientId,
        fields: payload.fields
      },
      {
        onSuccess: () => {
          toggleIsEdittingForm();
          props.onChangePatientClinicalInfo();
          setToastStatus({
            type: 'info',
            text: localize('clinicalInfoHasBeenUpdated')
          });
          onSuccess();
          sendEvent(
            AnalyticsEventName.PATIENT_CLINICAL_INFO_SUBMITTED_SUCCESS,
            createSubmitEventData()
          );
        },
        onError: () => {
          setToastStatus({
            type: 'error',
            text: localize('somethingWentWrong')
          });
          sendEvent(
            AnalyticsEventName.PATIENT_CLINICAL_INFO_SUBMITTED_FAILED,
            createSubmitEventData()
          );
        }
      }
    );
  };

  const createSubmitEventData = () => {
    return {
      viz_patient_id: patient.id,
      di_patient_mrn: patient.medicalRecordNumber,
      institution: patient.institutionName,
      action_text: localize('submitAndShare'),
      di_patient_name: patient.name,
      form_type: form?.formId
    };
  };

  useEffect(() => {
    if (isEdittingForm && form?.formId && form?.formId !== 'hcm_form') {
      setShowLoadingFormError(true);
    }

    return () => {
      setShowLoadingFormError(false);
    };
  }, [isLoadingForm, isEdittingForm, form]);

  const renderClinicalInfoSection = (
    items: ClinicalInfoItem[],
    sectionTitle?: ClinicalInfoSectionTitle,
    options?: Partial<ClinicalInfoSectionProps>
  ) => {
    const filterItemsByTitle = (sectionTitle: ClinicalInfoSectionTitle) => {
      const sectionItemsKeys = sectionTitleToItemKeys[sectionTitle];
      return items.filter((item) => sectionItemsKeys.includes(item.title));
    };

    const sectionItems = sectionTitle
      ? filterItemsByTitle(sectionTitle)
      : items;

    return (
      <>
        {sectionItems.length > 0 && (
          <ClinicalInfoSection
            enableFlexWrap={sectionTitle !== 'hcmClinicalInfo'}
            title={sectionTitle && localize(sectionTitle)}
            role={`${sectionTitle}-section`}
            items={sectionItems}
            {...options}
          />
        )}
      </>
    );
  };

  const { clinicalInfo: clinicalInfoItems } = patient;

  const allSectionsAreEmpty = clinicalInfoItems.length === 0;

  return (
    <>
      <StyledClinicalInfoLayout data-testid={props.testId}>
        {isLoadingForm ? (
          <StyledLoadingWrapper>
            <Loading size={IconSizes.LARGE} role="loader" />
          </StyledLoadingWrapper>
        ) : (
          <>
            <ClinicalInfoTitle>{localize('staffNotes')}</ClinicalInfoTitle>
            {allSectionsAreEmpty ? (
              <ClinicalInfoEmptyContent
                enableEdit={enableEdit}
                showError={showLoadingFormError}
                onClickEdit={toggleIsEdittingForm}
              />
            ) : (
              <>
                {renderClinicalInfoSection(
                  clinicalInfoItems.filter(
                    (item) => item.title === 'latestEditor'
                  )
                )}
                {renderClinicalInfoSection(clinicalInfoItems, 'background')}
                {renderClinicalInfoSection(clinicalInfoItems, 'timing')}
                {renderClinicalInfoSection(clinicalInfoItems, 'imaging')}
                {renderClinicalInfoSection(clinicalInfoItems, 'treatment')}
                {renderClinicalInfoSection(
                  clinicalInfoItems,
                  'hcmClinicalInfo',
                  {
                    enableEdit,
                    onClickEdit: toggleIsEdittingForm
                  }
                )}
              </>
            )}
          </>
        )}
      </StyledClinicalInfoLayout>
      {!isLoadingForm && form?.formId === 'hcm_form' && (
        <ClinicalInfoFormDialog
          form={form}
          open={isEdittingForm}
          title={localize('clinicalInformation')}
          subtitle={patient.name}
          onSubmit={(payload, onSuccess) =>
            handleOnSubmit(
              patient.id,
              payload as ClinicalInfoApi.SubmitPatientClinicalInfoFormPayload,
              onSuccess
            )
          }
          isEditMode={isEdittingForm && !allSectionsAreEmpty}
          isSubmitting={isSubmittingForm}
          onClose={toggleIsEdittingForm}
        />
      )}
    </>
  );
});

interface ClinicalInfoEmptyContentProps {
  enableEdit: boolean;
  showError: boolean;
  onClickEdit: () => void;
}

const ClinicalInfoEmptyContent = (props: ClinicalInfoEmptyContentProps) => {
  return (
    <>
      <ClinicalInfoEmptyStateLayout>
        <span>{localize('noStaffNotes')}</span>
        {props.enableEdit && (
          <Button
            color="secondary"
            variant="outlined"
            onClick={props.onClickEdit}
            startIcon={<CirclePlusIcon size={IconSizes.X_SMALL} />}
          >
            {localize('addNotes')}
          </Button>
        )}
      </ClinicalInfoEmptyStateLayout>
      {props.showError && (
        <Alert
          type="error"
          text={localize('errorDisplayingClinicalInformation')}
        />
      )}
    </>
  );
};
