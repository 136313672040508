import { Patient } from '@viz/api';
import { withVisibility } from '../../../../hocs';
import { StyledPatientChannelsContainer } from './styles';


export type PatientChannelsProps = {
  patient: Patient;
  apiKey: string;
  token: string;
};

const PatientChannels = ({
  patient,
  apiKey,
  token
}: PatientChannelsProps): JSX.Element => {

  return (
      <StyledPatientChannelsContainer>
        new patient chat displayed
      </StyledPatientChannelsContainer>
  );
};

export default withVisibility(PatientChannels);
