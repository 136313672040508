import {
  patientDataModification,
  seriesMetadataTransform,
  usePatient,
  useSeriesMetadata
} from '@viz/api';
import { Widget } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { PatientFolder, Viewer } from '../../components';
import { ECGViewer } from '../../components/ECGViewer/ECGViewer';
import { useAuth, useRouter } from '../../hooks';
import {
  isViewerVisibleState,
  patientViewerDataStateFamily
} from '../../store';
import { PatientDataFooter } from './PatientDataFooter';
import { ViewerDisclaimerDialog } from './ViewerDisclaimerDialog';
import {
  Content,
  StyledDialog,
  StyledPatientFolderGrid,
  StyledTabPanel,
  StyledViewerGrid
} from './styles';
import {
  getEcgStudyData,
  getInstitutionId,
  getStudyData,
  isInstitutionIdValid
} from './utils';

const PatientData = (): JSX.Element => {
  const [isPEADDialogOpen, setIsPEADDialogOpen] = useState(false);
  const [isDisclaimerDialogOpen, setIsDisclaimerDialogOpen] = useState(false);
  const { isAuth, isLoading } = useAuth();
  const { getUrlParam } = useRouter();
  const patientId = getUrlParam('id') as string | undefined;
  const studyUid = getUrlParam('study_id') as string;
  const seriesUid = getUrlParam('series_id') as string;
  const ecgId = getUrlParam('ecg_id') as string;
  const setPatientViewerData = useSetRecoilState(
    patientViewerDataStateFamily(patientId || '')
  );
  const setIsViewerVisible = useSetRecoilState(isViewerVisibleState);
  const { data: patient } = usePatient(
    {
      enabled: Boolean(patientId),
      refetchOnWindowFocus: false,
      select: patientDataModification
    },
    { patientId }
  );

  const { study, series } = getStudyData(patient?.studies, studyUid, seriesUid);
  const { ecgStudy } = getEcgStudyData(patient?.ecgs, ecgId);
  const institutionId = getInstitutionId(study);

  const { data: seriesMetadata } = useSeriesMetadata(
    {
      enabled: isInstitutionIdValid(institutionId),
      refetchOnWindowFocus: false,
      select: seriesMetadataTransform,
      onSuccess: (data) => {
        if (data?.announcementData?.message) {
          setIsPEADDialogOpen(true);
        }
      }
    },
    { institutionId, studyId: studyUid, seriesId: seriesUid }
  );

  useEffect(() => {
    if (patientId) {
      setPatientViewerData({ studyUid, seriesUid, ecgId });
    }
  }, [studyUid, seriesUid, setPatientViewerData, ecgId, patientId]);

  useEffect(() => {
    setIsViewerVisible(Boolean(patientId));
  }, [patientId, setIsViewerVisible]);

  if (!patientId || isLoading || !isAuth) return <></>;

  return (
    <StyledTabPanel
      value={patientId}
      selectedValue={patientId}
      $isDisplayed={Boolean(patientId)}
    >
      <StyledDialog
        open={isPEADDialogOpen}
        primaryButtonProps={{
          content: localize('ok'),
          onClick: () => {
            setIsPEADDialogOpen(false);
          }
        }}
        title=""
      >
        <p>{seriesMetadata?.announcementData?.message}</p>
      </StyledDialog>
      {seriesMetadata?.readMoreDisclaimer && (
        <ViewerDisclaimerDialog
          title={seriesMetadata.readMoreDisclaimer.title}
          message={seriesMetadata.readMoreDisclaimer.message}
          isOpen={isDisclaimerDialogOpen}
          onClose={() => setIsDisclaimerDialogOpen(false)}
        />
      )}
      <Content container spacing={2}>
        <StyledPatientFolderGrid item xs={4} xl={3}>
          <PatientFolder
            patientId={patientId}
            testId="patient-data-patient-folder"
            expandDesktopWindowMaxSize={true}
          />
        </StyledPatientFolderGrid>
        <StyledViewerGrid item xs={8} xl={9}>
          <Widget padding={0} fillSpace height="100%">
            {ecgStudy ? (
              <ECGViewer
                patient={patient}
                ecgId={ecgId}
                patientName={patient?.name}
                lastArrivalTs={ecgStudy?.lastArrivalTs}
              />
            ) : (
              <Viewer
                seriesMetadata={seriesMetadata}
                patient={patient}
                study={study}
                series={series}
                studyUid={studyUid}
                seriesUid={seriesUid}
                onDisclaimerClick={() => setIsDisclaimerDialogOpen(true)}
              />
            )}
          </Widget>
        </StyledViewerGrid>
      </Content>
      <PatientDataFooter patientId={patientId} />
    </StyledTabPanel>
  );
};

export default PatientData;
