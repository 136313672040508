import { getActiveLanguage } from '@viz/i18n';
import { getDateAndTime, isCurrentYear } from '@viz/utils';

export function timestampToDatetime(value: string): string {
  const date = Number(value);
  return getDateAndTime(date, {
    language: getActiveLanguage(),
    formatting: isCurrentYear(date) ? 'HH:mm MMM dd' : 'HH:mm MMM dd yyyy'
  });
}
