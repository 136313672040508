import { ClinicalInfoItem } from '@viz/api';
import { IconEdit, IconSizes } from '@viz/design-system';

import { ClinicalInfoSectionItem } from '../ClinicalInfoSectionItem';
import {
  StyledClinicalInfoSectionBody,
  StyledClinicalInfoSectionHeader,
  StyledClinicalInfoSectionWidget,
  StyledIconButton,
  StyledSectionTitle
} from './styles';

export interface ClinicalInfoSectionProps {
  items: ClinicalInfoItem[];
  enableFlexWrap?: boolean;
  role?: string;
  title?: string;
  enableEdit?: boolean;
  onClickEdit?: () => void;
}

export const ClinicalInfoSection = (props: ClinicalInfoSectionProps) => {
  const { title, items, enableEdit, enableFlexWrap } = props;

  return (
    <StyledClinicalInfoSectionWidget role={props.role}>
      {title && (
        <StyledClinicalInfoSectionHeader>
          <StyledSectionTitle>{title}</StyledSectionTitle>
          {enableEdit && (
            <StyledIconButton
              onClick={props.onClickEdit}
              role="button-edit-icon"
            >
              <IconEdit size={IconSizes.MEDIUM} />
            </StyledIconButton>
          )}
        </StyledClinicalInfoSectionHeader>
      )}
      <StyledClinicalInfoSectionBody $enableFlexWrap={Boolean(enableFlexWrap)}>
        {items.map((item, index) => {
          const key = `${item.title}-${index}`;
          return item && <ClinicalInfoSectionItem key={key} item={item} />;
        })}
      </StyledClinicalInfoSectionBody>
    </StyledClinicalInfoSectionWidget>
  );
};
