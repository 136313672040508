import { ServerPatients } from '@viz/api';
import { IconActivate, IconRefer, IconSizes } from '@viz/design-system';
import { useEffect, useState } from 'react';

import {
  IconContainer,
  StyledArrowIcon,
  StyledMenu,
  StyledMenuBar,
  StyledMenuItem,
  StyledName
} from './styles';

export interface SelectDistributionListProps {
  distributionLists: ServerPatients.DistributionList[];
  shareType: ServerPatients.ShareType;
  onChange: (distributionList: ServerPatients.DistributionList) => void;
  testId?: string;
}

export const SelectDistributionList = (props: SelectDistributionListProps) => {
  const { distributionLists, shareType, onChange } = props;

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [relevantDistributionLists, setRelevantDistributionLists] = useState<
    ServerPatients.DistributionList[]
  >([]);
  const isActivate = shareType === 'ACTIVATE';

  useEffect(() => {
    const filtered = distributionLists.filter(
      (d) => d.share_type === shareType
    );
    setRelevantDistributionLists(filtered);
  }, [distributionLists, shareType]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleOnClick = (distributionList: ServerPatients.DistributionList) => {
    onChange(distributionList);
    handleMenuClose();
  };

  return relevantDistributionLists.length ? (
    <>
      <StyledMenuBar
        data-testid={`select-${isActivate ? 'activate' : 'refer'}`}
        onClick={handleMenuOpen}
        color="primary"
      >
        {isActivate ? (
          <IconActivate size={IconSizes.SMALL}></IconActivate>
        ) : (
          <IconRefer size={IconSizes.SMALL}></IconRefer>
        )}
        <label> {isActivate ? 'Activate' : 'Refer'} </label>
        <IconContainer>
          <StyledArrowIcon
            size={IconSizes.XX_SMALL}
            isDown={!Boolean(menuAnchorEl)}
          />
        </IconContainer>
      </StyledMenuBar>
      <StyledMenu
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={handleMenuClose}
      >
        {relevantDistributionLists.map((distributionList) => (
          <StyledMenuItem
            key={distributionList.id}
            onClick={() => handleOnClick(distributionList)}
          >
            {isActivate ? 'Activate' : 'Refer to'}
            <StyledName> {distributionList.name} </StyledName>
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  ) : (
    <></>
  );
};
