import { analyticsService } from '@viz/analytics';
import {
  GetProfileData,
  LoginError,
  LoginErrorReason,
  LoginPayload,
  LoginResponseData,
  ShiftResponseData,
  useLogin,
  useMfaSendCode,
  useMfaVerifyCode,
  useProfile,
  UserAuthInfo,
  useShiftStatus,
  useUserAuthInfo,
  useVerifySsoToken,
  VerifySsoTokenPayload
} from '@viz/api';
import { logger as loggingService } from '@viz/logging';
import { monitoringService } from '@viz/monitoring';
import { getDeviceUid } from '@viz/utils';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { browserStorage } from '../../managers';
import { loginStepState } from '../../store';
import { useWidgetPostSsoAuthCode } from '../desktop-app';
import {
  AnalyticsEventMethod,
  AnalyticsEventName,
  useAnalyticsEvent
} from '../useAnalytics';
import { useLoginWatcher } from '../useLoginWatcher';
import { usePolicies } from '../usePolicies';
import {
  castLoginSuccessfulEventPayload,
  findUserSsoProvider,
  normalizeAuthenticationError,
  NormalizedAuthenticationError
} from './utils';

const BASE_OPTIONS = { retry: false, refetchOnWindowFocus: false };

type UserCredentials = Pick<LoginPayload, 'username' | 'password'>;
export interface UseLoginUserOptions {
  phoneNumber: string;
  authenticationError: NormalizedAuthenticationError | null;
  isLoading: boolean;
  verifyMfa: (code: string) => void;
  submitLogin: (payload: UserCredentials) => void;
  resetLoginError: () => void;
  username: string;
  verifySsoCode: (code: string) => void;
  resendMfaCode: () => void;
}

const useLoginUser = (): UseLoginUserOptions => {
  const { sendEvent } = useAnalyticsEvent();
  const device_uid = getDeviceUid();
  const getUserAuthenticationInfo = useUserAuthInfo();
  const { isLoading: isPoliciesLoading } = usePolicies();
  const [authenticationError, setAuthenticationError] =
    useState<NormalizedAuthenticationError | null>(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginStep, setLoginStep] = useRecoilState(loginStepState);
  const { code: widgetPostSsoAuthCode } = useWidgetPostSsoAuthCode();
  const profileQuery = useProfile({ enabled: false });
  useLoginWatcher();

  const handleSSOLoginProvider = useCallback(
    (ssoLoginProvider: UserAuthInfo.LoginProvider, username: string) => {
      browserStorage.ssoUsername.set(username);
      if (window.vizWidget) {
        window.vizWidget.openInExternalBrowser(ssoLoginProvider.login_url);
        setLoginStep('continueAuthInExternalBrowser');
      } else {
        window.location.replace(ssoLoginProvider.login_url);
      }
    },
    [setLoginStep]
  );

  useEffect(() => {
    const data = getUserAuthenticationInfo.data;
    if (data) {
      const ssoLoginProvider = findUserSsoProvider(data);
      if (ssoLoginProvider) {
        handleSSOLoginProvider(ssoLoginProvider, username);
      } else {
        setLoginStep('password');
      }
    }
    // we don't want to track getUserAuthenticationInfo since it changes on every login step change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserAuthenticationInfo.data, setLoginStep, handleSSOLoginProvider]);

  useEffect(() => {
    const { error } = getUserAuthenticationInfo;
    if (error && error.response) {
      const err = normalizeAuthenticationError(error.response.data);
      setAuthenticationError(err);
    }
    // we don't want to track getUserAuthenticationInfo since it changes on every login step change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserAuthenticationInfo.error]);

  const onChangeUsername = useCallback(
    (username: string) => {
      // we need to mutate here and not track username changes because clicking on back and then 'next' withot chaging the username
      // calls this function, track username in this case will not invoke the effect
      getUserAuthenticationInfo.mutate({ username });
      setUsername(username);
    },
    [getUserAuthenticationInfo]
  );

  const identifyProfile = (
    profile: GetProfileData,
    shiftData: ShiftResponseData
  ) => {
    const { user_uid, email, name } = profile;

    const eventData = castLoginSuccessfulEventPayload(profile, shiftData);
    sendEvent(AnalyticsEventName.LOGIN_SUCCESSFUL, eventData);

    analyticsService.identifyUser(user_uid);
    analyticsService.setUserProfile({ user_uid });

    monitoringService.identifyUser({ id: user_uid, email, name });
    loggingService.identifyUser({
      user_uid,
      user_email: email,
      user_name: name
    });
  };

  const { refetch: refetchShiftStatus } = useShiftStatus({ enabled: false });

  const onLoginSuccess = async (loginResponse: LoginResponseData) => {
    browserStorage.csrfAccessToken.set(loginResponse.csrf_access_token);
    browserStorage.accessTokenExpiration.set(loginResponse.access_expiration);
    browserStorage.encryptionKey.set(loginResponse.encryption_key);
    browserStorage.userAccessEnabled.set(loginResponse.user_access);
    if(loginResponse.chat){
      browserStorage.userChatAccess.set(loginResponse.chat);
    }

    const { data: shiftData } = await refetchShiftStatus();
    const { data: profile } = await profileQuery.refetch();
    if (profile && shiftData) {
      identifyProfile(profile, shiftData);
    }
  };

  const { mutate: login, isLoading: isLoadingLogin } = useLogin({
    ...BASE_OPTIONS,
    onSuccess: onLoginSuccess,
    onError: (error, variables) => onLoginError(error, variables)
  });

  const { mutate: verifySsoToken, isLoading: isLoadingVerifySsoToken } =
    useVerifySsoToken({
      ...BASE_OPTIONS,
      onSuccess: onLoginSuccess,
      onError: (error, variables) => onLoginError(error, variables),
      onSettled: () => browserStorage.ssoUsername.remove()
    });

  const { mutate: sendMfaSms, isLoading: isLoadingSendMfaSms } = useMfaSendCode(
    {
      ...BASE_OPTIONS,
      onSuccess: (data) => {
        const { success, phone_number } = data;
        if (success === 'true') {
          setPhoneNumber(phone_number);
          setLoginStep('mfa');
        } else {
          setAuthenticationError(normalizeAuthenticationError(data.error!));
        }
      }
    }
  );

  const { mutate: verifyMfaCode } = useMfaVerifyCode({
    ...BASE_OPTIONS,
    onSuccess: (data) => {
      if (data.success === 'true') {
        submitLogin({ username, password });
      } else {
        setAuthenticationError(normalizeAuthenticationError(data.error!));
      }
    }
  });

  const onLoginError = (
    error: AxiosError<LoginError>,
    variables: LoginPayload | VerifySsoTokenPayload
  ) => {
    const err = normalizeAuthenticationError(error.response!.data);

    if (err.reason === LoginErrorReason.MFA_REQUIRED) {
      const loginVariables = variables as LoginPayload;
      const { username, password } = loginVariables;
      setUsername(username);
      setPassword(password);
      sendMfaSms(loginVariables);
    } else {
      setAuthenticationError(err);
      sendEvent(AnalyticsEventName.LOGIN_FAILED, {
        method: AnalyticsEventMethod.LOGIN,
        error: 'invalid credentials'
      });
    }
  };

  const verifySsoCode = useCallback(
    (code: string) => {
      const email = browserStorage.ssoUsername.get();
      verifySsoToken({ code, email });
    },
    [verifySsoToken]
  );

  const submitLogin = (payload: UserCredentials): void => {
    sendEvent(AnalyticsEventName.LOGIN_BUTTON_CLICKED);
    login({ ...payload, device_token: device_uid });
  };

  useEffect(() => {
    if (widgetPostSsoAuthCode) {
      verifySsoCode(widgetPostSsoAuthCode);
    }
  }, [verifySsoCode, widgetPostSsoAuthCode]);

  return {
    phoneNumber,
    authenticationError,
    isLoading:
      isLoadingLogin ||
      isLoadingSendMfaSms ||
      getUserAuthenticationInfo.isLoading ||
      isLoadingVerifySsoToken ||
      isPoliciesLoading,
    verifyMfa: (code: string): void => {
      verifyMfaCode({ username, password, code, device_uid });
    },
    submitLogin:
      loginStep === 'username'
        ? (p) => onChangeUsername(p.username)
        : submitLogin,
    resetLoginError: (): void => {
      setAuthenticationError(null);
    },
    username,
    verifySsoCode,
    resendMfaCode: () => {
      sendMfaSms({ username, password } as LoginPayload);
    }
  };
};

export default useLoginUser;
