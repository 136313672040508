import {
  AckMessagesDocument,
  AckSeries,
  AckStudy,
  AdminEndpoints,
  chatAckMessageData,
  ChatAckMessagesData,
  ChatConversationData,
  chatSendMessageData,
  ChatSendMessageData,
  ChatUploadMultipleFiles,
  chatUploadMultipleFilesData,
  ChatUploadSingleFile,
  chatUploadSingleFileData,
  ClientConfig,
  ClinicalInfo,
  ContactSupportData,
  ConversationDocument,
  createChatConversationData,
  createFakeAckSeries,
  createFakeAckStudy,
  createFakeNotificationMessageData,
  createFakeNotificationPatientData,
  createFakePatient,
  createFakePatientClinicalInfoForm,
  createFakePatients,
  createFakeThumbnail,
  createFakeUserList,
  createUnreadMessagesData,
  Endpoints,
  FAKE_THUMBNAIL_URL,
  fakeContactSupportResponse,
  fakeCustomerAccountsList,
  fakeExistUser,
  fakeInstitutionsList,
  fakeNotificationSettingsResponse,
  fakeRolesList,
  fakeSendMfaSmsResponseData,
  fakeUser,
  fakeUserGroupsList,
  fakeVerifyMfaCodeResponse,
  getFakeInstitutionFilterResponse,
  GetProfileData,
  HTTPStatusCode,
  injectURLFakeParams,
  LoginError,
  LoginResponseData,
  Logout,
  MfaSendCodeResponseData,
  NotificationData,
  ResetPasswordResponseData,
  SendMessageDocument,
  SeriesThumbnailUrl,
  ServerCustomerAccounts,
  ServerInstitutionFilter,
  ServerInstitutions,
  ServerNotificationSettings,
  ServerPatient,
  ServerPatients,
  ServerRoles,
  ServerSeriesMetadata,
  ServerUser,
  ServerUserGroups,
  ServerUsers,
  UnreadMessages,
  UploadMultipleFilesDocument,
  UploadSingleFileDocument,
  UserAuthInfo,
  VerifyMfaCodeResponseData
} from '@viz/api';
import { fakeCreateInvitation } from '@viz/api/src/admin/invitations/mockData';
import {
  ServerUserPolicyData,
  SignPolicyServerResponse
} from '@viz/api/src/policies/types';
import { createFakeSeriesMetadata } from '@viz/api/src/studies/mockData';
import { AxiosRequestConfig } from 'axios';

import { mockAdapter } from './adapter';
import {
  FAKE_ACCESS_TOKEN_COOKIE,
  FAKE_CLIENT_CONFIG,
  FAKE_DOCUMENTATION,
  FAKE_LOGIN_RESPONSE,
  FAKE_REFRESH_ACCESS_TOKEN_RESPONSE,
  FAKE_RESET_PASSWORD_RESPONSE,
  FAKE_USER,
  FAKE_USER_AUTH_INFO_NO_SSO,
  FAKE_USER_POLICIES_RESPONSE,
  FAKE_USER_SIGN_POLICY_RESPONSE
} from './data';
import { mockGraphQL, protectRequest } from './utils';

const filterPatientsByQuery = (
  patients: ServerPatients.ServerPatient[],
  query: string
) => {
  return patients.filter(
    (patient) =>
      patient.patient_details.name
        .toLowerCase()
        .includes(query.toLowerCase()) ||
      patient.patient_details.medical_record_number.includes(query)
  );
};

type MockPatientsData = (
  config: AxiosRequestConfig
) => ServerPatients.ServerResponse;

export const mockPatients = (response = createFakePatients()): void => {
  protectRequest<MockPatientsData>(
    mockAdapter.instance.onGet(
      new RegExp(injectURLFakeParams(Endpoints.GET_PATIENTS))
    ),
    (config) => {
      let allPatients = response.all_patients;

      if (config.params.query) {
        allPatients = response.all_patients.map((patientsObj) => ({
          ...patientsObj,
          patients: filterPatientsByQuery(
            patientsObj.patients,
            config.params.query
          )
        }));
      }

      return {
        ...response,
        all_patients: allPatients
      };
    }
  );
};

export const mockPatient = (response = createFakePatient()): void => {
  protectRequest<ServerPatient.Data>(
    mockAdapter.instance.onGet(
      new RegExp(injectURLFakeParams(Endpoints.GET_PATIENT))
    ),
    response
  );
};

export const mockThumbnailUrl = (): void => {
  protectRequest<SeriesThumbnailUrl.ServerResponse>(
    mockAdapter.instance.onGet(
      new RegExp(injectURLFakeParams(Endpoints.GET_SERIES_THUMBNAIL))
    ),
    { url: FAKE_THUMBNAIL_URL }
  );
};

export const mockThumbnail = (): void => {
  mockThumbnailUrl();

  protectRequest<Blob>(
    mockAdapter.instance.onGet(new RegExp(FAKE_THUMBNAIL_URL)),
    createFakeThumbnail('img', 5000)
  );
};

export const mockProfile = (): void => {
  protectRequest<GetProfileData>(
    mockAdapter.instance.onGet(new RegExp(Endpoints.PROFILE)),
    FAKE_USER
  );
};

type MockLoginParams = {
  status?: HTTPStatusCode;
  response?: LoginResponseData | LoginError;
};

export const mockLogin = ({
  status = HTTPStatusCode.OK,
  response = FAKE_LOGIN_RESPONSE
}: MockLoginParams = {}): void => {
  document.cookie = FAKE_ACCESS_TOKEN_COOKIE;
  mockAdapter.mock<LoginResponseData | LoginError>({
    url: new RegExp(Endpoints.LOGIN),
    status,
    method: 'onPost',
    data: response
  });
};

export const mockRefreshAccessToken = (): void => {
  protectRequest<void>(
    mockAdapter.instance.onPost(
      new RegExp(Endpoints.REFRESH_ACCESS_TOKEN),
      FAKE_REFRESH_ACCESS_TOKEN_RESPONSE
    )
  );
};

export const mockLogout = (): void => {
  mockAdapter.mock<Logout.ResponseData>({
    url: new RegExp(Endpoints.LOGOUT),
    status: HTTPStatusCode.OK,
    method: 'onGet',
    data: {
      success: 'true',
      user_uid: '123'
    }
  });
};

export const mockShifts = (onShift = false): void => {
  const isOnShift = () => ({
    allowed_applications: [],
    on_shift: onShift
  });
  const toggleShift = () => {
    onShift = !onShift;
    return { on_shift: onShift };
  };

  protectRequest(
    mockAdapter.instance.onGet(new RegExp(Endpoints.START_SHIFT)),
    toggleShift
  );
  protectRequest(
    mockAdapter.instance.onGet(new RegExp(Endpoints.END_SHIFT)),
    toggleShift
  );

  protectRequest(
    mockAdapter.instance.onGet(new RegExp(Endpoints.GET_SHIFT_STATUS)),
    isOnShift
  );
};

export const mockDocumentation = (): void => {
  protectRequest(
    mockAdapter.instance.onGet(new RegExp(Endpoints.GET_DOCUMENTATION)),
    FAKE_DOCUMENTATION
  );
};

export const mockResetPassword = (): void => {
  mockAdapter.mock<ResetPasswordResponseData>({
    url: new RegExp(Endpoints.RESET_PASSWORD),
    status: HTTPStatusCode.OK,
    method: 'onPost',
    data: FAKE_RESET_PASSWORD_RESPONSE
  });
};

export const mockSaveNotificationToken = () => {
  mockAdapter.mock({
    url: new RegExp(Endpoints.SAVE_NOTIFICATION_TOKEN),
    status: HTTPStatusCode.OK,
    method: 'onPost',
    data: {
      web_update_ts: 1632307313753
    }
  });
};

export const mockUnreadMessagesAmount = (
  response = createUnreadMessagesData()
): void => {
  protectRequest<UnreadMessages.ServerResponse>(
    mockAdapter.instance.onGet(
      new RegExp(injectURLFakeParams(Endpoints.GET_UNREAD_COMMENTS_AMOUNT))
    ),
    response
  );
};

export const mockConversation = (
  response = createChatConversationData()
): void => {
  mockGraphQL<ChatConversationData>(
    Endpoints.QUERY_CHAT,
    ConversationDocument,
    response
  );
};

export const mockAckMessages = (response = chatAckMessageData): void => {
  mockGraphQL<ChatAckMessagesData>(
    Endpoints.QUERY_CHAT,
    AckMessagesDocument,
    response
  );
};

export const mockSendMessage = (
  response = chatSendMessageData,
  statusCode = HTTPStatusCode.OK
): void => {
  mockGraphQL<ChatSendMessageData>(
    Endpoints.QUERY_CHAT,
    SendMessageDocument,
    response,
    true,
    statusCode
  );
};

export const mockUploadSingleFile = (
  response = chatUploadSingleFileData
): void => {
  mockGraphQL<ChatUploadSingleFile>(
    Endpoints.QUERY_CHAT,
    UploadSingleFileDocument,
    response
  );
};

export const mockUploadMultipleFiles = (
  response = chatUploadMultipleFilesData
): void => {
  mockGraphQL<ChatUploadMultipleFiles>(
    Endpoints.QUERY_CHAT,
    UploadMultipleFilesDocument,
    response
  );
};

export const mockNotificationPatientData = (
  response = createFakeNotificationPatientData()
): void => {
  protectRequest<NotificationData.PatientDataResponse>(
    mockAdapter.instance.onGet(
      new RegExp(injectURLFakeParams(Endpoints.GET_NOTIFICATION_PATIENT_DATA))
    ),
    response
  );
};

export const mockNotificationMessageData = (
  response = createFakeNotificationMessageData()
): void => {
  protectRequest<NotificationData.MessageDataResponse>(
    mockAdapter.instance.onGet(
      new RegExp(injectURLFakeParams(Endpoints.GET_NOTIFICATION_MESSAGE_DATA))
    ),
    response
  );
};

export const mockMfa = (): void => {
  mockAdapter.mock<MfaSendCodeResponseData>({
    url: new RegExp(Endpoints.SEND_MFA_SMS),
    status: HTTPStatusCode.OK,
    method: 'onPost',
    data: fakeSendMfaSmsResponseData
  });

  mockAdapter.mock<VerifyMfaCodeResponseData>({
    url: new RegExp(Endpoints.VERIFY_MFA_CODE),
    status: HTTPStatusCode.OK,
    method: 'onPost',
    data: fakeVerifyMfaCodeResponse
  });
};

export const mockSeriesMetadata = (
  response = createFakeSeriesMetadata()
): void => {
  protectRequest<ServerSeriesMetadata.SeriesMetadataResponseData>(
    mockAdapter.instance.onGet(
      new RegExp(injectURLFakeParams(Endpoints.GET_SERIES_METADATA))
    ),
    response
  );
};

export const mockAckStudy = (response = createFakeAckStudy()): void => {
  protectRequest<AckStudy.Response>(
    mockAdapter.instance.onPut(
      new RegExp(injectURLFakeParams(Endpoints.ACK_STUDY))
    ),
    response
  );
};

export const mockAckSeries = (response = createFakeAckSeries()): void => {
  protectRequest<AckSeries.Response>(
    mockAdapter.instance.onPut(
      new RegExp(injectURLFakeParams(Endpoints.ACK_SERIES))
    ),
    response
  );
};

export const mockUserAuthInfo = (
  response = FAKE_USER_AUTH_INFO_NO_SSO
): void => {
  mockAdapter.mock<UserAuthInfo.Response>({
    url: new RegExp(Endpoints.USER_AUTH_INFO),
    status: HTTPStatusCode.OK,
    method: 'onPost',
    data: response
  });
};

export const mockVerifySsoToken = ({
  status = HTTPStatusCode.OK,
  response = FAKE_LOGIN_RESPONSE
}: MockLoginParams = {}): void => {
  mockAdapter.mock<LoginResponseData | LoginError>({
    url: new RegExp(Endpoints.VERIFY_SSO_TOKEN),
    status,
    method: 'onPost',
    data: response
  });
};

export const mockNotificationSettings = (
  response = fakeNotificationSettingsResponse
): void => {
  protectRequest<ServerNotificationSettings.ResponseData>(
    mockAdapter.instanceGW.onGet(
      new RegExp(Endpoints.GET_USER_NOTIFICATION_SETTINGS)
    ),
    response
  );
};

export const mockUpdateNotificationSettings = (
  response = fakeNotificationSettingsResponse
): void => {
  protectRequest<ServerNotificationSettings.ResponseData>(
    mockAdapter.instanceGW.onPost(
      new RegExp(injectURLFakeParams(Endpoints.UPDATE_NOTIFICATION_SETTINGS))
    ),
    response
  );
};

export const mockResetNotificationSettings = (): void => {
  protectRequest<ServerNotificationSettings.ResponseData>(
    mockAdapter.instanceGW.onPost(
      new RegExp(Endpoints.RESET_NOTIFICATION_SETTINGS)
    ),
    fakeNotificationSettingsResponse
  );
};

export const mockInstitutionSettings = (
  response = getFakeInstitutionFilterResponse()
): void => {
  protectRequest<ServerInstitutionFilter.ResponseData>(
    mockAdapter.instance.onGet(new RegExp(Endpoints.USER_INSTITUTION_SETTINGS)),
    response
  );
};

export const mockUpdateInstitutionSettings = (
  response = getFakeInstitutionFilterResponse()
): void => {
  protectRequest<ServerInstitutionFilter.ResponseData>(
    mockAdapter.instance.onPut(new RegExp(Endpoints.USER_INSTITUTION_SETTINGS)),
    response
  );
};

export const mockUpdateProfileSendMfaCode = (): void => {
  protectRequest<MfaSendCodeResponseData>(
    mockAdapter.instance.onPost(
      new RegExp(Endpoints.UPDATE_PROFILE_SEND_MFA_CODE)
    ),
    fakeSendMfaSmsResponseData
  );
};

export const mockUpdateProfileVerifyMfaCode = (
  response = fakeVerifyMfaCodeResponse
): void => {
  protectRequest<VerifyMfaCodeResponseData>(
    mockAdapter.instance.onPost(
      new RegExp(Endpoints.UPDATE_PROFILE_VERIFY_MFA_CODE)
    ),
    response
  );
};

export const mockUpdateUserProfile = (): void => {
  protectRequest<GetProfileData>(
    mockAdapter.instance.onPut(new RegExp(Endpoints.PROFILE)),
    FAKE_USER
  );
};

export const mockAdminUsers = (
  response = createFakeUserList({ isInfiniteScroll: true })
): void => {
  protectRequest<ServerUsers.ListResponse>(
    mockAdapter.instanceGW.onGet(AdminEndpoints.USERS),
    response
  );
};

export const mockAdminUser = (): void => {
  protectRequest<ServerUser.UserResponse>(
    mockAdapter.instanceGW.onGet(
      new RegExp(injectURLFakeParams(AdminEndpoints.USER))
    ),
    fakeUser
  );
};

export const mockRoles = (): void => {
  protectRequest<ServerRoles.ListResponseItemsRole>(
    mockAdapter.instanceGW.onGet(AdminEndpoints.ROLES),
    fakeRolesList
  );
};

export const mockUserGroups = (): void => {
  protectRequest<ServerUserGroups.ListResponseItemsUserGroup>(
    mockAdapter.instanceGW.onGet(AdminEndpoints.USER_GROUPS),
    fakeUserGroupsList
  );
};

export const mockCustomerAccounts = (): void => {
  protectRequest<ServerCustomerAccounts.ListResponseCustomerAccount>(
    mockAdapter.instanceGW.onGet(AdminEndpoints.CUSTOMER_ACCOUNTS),
    fakeCustomerAccountsList
  );
};

export const mockInstitutions = (): void => {
  protectRequest<ServerInstitutions.ListResponseItemsInstitution>(
    mockAdapter.instanceGW.onGet(AdminEndpoints.INSTITUTIONS),
    fakeInstitutionsList
  );
};

export const mockEditUser = ({
  status = HTTPStatusCode.OK
}: MockLoginParams = {}): void => {
  protectRequest<{}>(
    mockAdapter.instanceGW.onPatch(
      new RegExp(injectURLFakeParams(AdminEndpoints.USER))
    ),
    {}
  );
};

export const mockRemoveUser = ({
  status = HTTPStatusCode.OK
}: MockLoginParams = {}): void => {
  protectRequest<{}>(
    mockAdapter.instanceGW.onDelete(
      new RegExp(injectURLFakeParams(AdminEndpoints.USER))
    ),
    {}
  );
};

export const mockCreateInvitation = (): void => {
  protectRequest<String>(
    mockAdapter.instanceGW.onPost(AdminEndpoints.CREATE_INVITATION),
    fakeCreateInvitation
  );
};

export const mockExistUser = (): void => {
  protectRequest<ServerUser.ExistUserResponse>(
    mockAdapter.instanceGW.onGet(AdminEndpoints.EXIST_USER),
    fakeExistUser
  );
};

export const mockContactSupport = () => {
  protectRequest<ContactSupportData>(
    mockAdapter.instance.onPost(Endpoints.CONTACT_US),
    fakeContactSupportResponse
  );
};

export const mockUserPolicies = (response = FAKE_USER_POLICIES_RESPONSE) => {
  protectRequest<ServerUserPolicyData.ResponseData>(
    mockAdapter.instance.onPost(Endpoints.GET_USER_POLICY_DATA),
    response
  );
};

export const mockPolicy = (policyUrl: string, response = 'fake policy') => {
  protectRequest<string>(mockAdapter.instance.onGet(policyUrl), response);
};

export const mockSignPolicy = () => {
  protectRequest<SignPolicyServerResponse>(
    mockAdapter.instance.onPost(Endpoints.SIGN_POLICY),
    FAKE_USER_SIGN_POLICY_RESPONSE
  );
};

export const mockClientConfig = () => {
  protectRequest<ClientConfig>(
    mockAdapter.instance.onGet(Endpoints.GET_CLIENT_CONFIG),
    FAKE_CLIENT_CONFIG
  );
};

export const mockGetPatientClinicalInfoForm = (
  response = createFakePatientClinicalInfoForm()
): void => {
  protectRequest<ClinicalInfo.GetPatientClinicalInfoFormServerResponse>(
    mockAdapter.instance.onGet(
      new RegExp(injectURLFakeParams(Endpoints.GET_PATIENT_CLINICAL_INFO_FORM))
    ),
    response
  );
};

export const mockSubmitPatientClinicalInfoForm = (
  response = { success: true }
): void => {
  protectRequest<ClinicalInfo.SubmitPatientClinicalInfoFormServerResponse>(
    mockAdapter.instance.onPost(
      new RegExp(
        injectURLFakeParams(Endpoints.SUBMIT_PATIENT_CLINICAL_INFO_FORM)
      )
    ),
    response
  );
};

export const mockAllRequests = (): void => {
  mockLogin();
  mockLogout();
  mockRefreshAccessToken();
  mockShifts();
  mockProfile();
  mockPatients();
  mockDocumentation();
  mockResetPassword();
  mockPatient();
  mockSeriesMetadata();
  mockThumbnail();
  mockConversation();
  mockSendMessage();
  mockAckMessages();
  mockUnreadMessagesAmount();
  mockUploadSingleFile();
  mockUploadMultipleFiles();
  mockSaveNotificationToken();
  mockNotificationPatientData();
  mockNotificationMessageData();
  mockMfa();
  mockAckStudy();
  mockAckSeries();
  mockUserAuthInfo();
  mockVerifySsoToken();
  mockNotificationSettings();
  mockUpdateNotificationSettings();
  mockResetNotificationSettings();
  mockInstitutionSettings();
  mockUpdateInstitutionSettings();
  mockUpdateProfileSendMfaCode();
  mockUpdateProfileVerifyMfaCode();
  mockUpdateUserProfile();
  mockExistUser();
  mockAdminUsers();
  mockAdminUser();
  mockRoles();
  mockUserGroups();
  mockInstitutions();
  mockEditUser();
  mockRemoveUser();
  mockCreateInvitation();
  mockCustomerAccounts();
  mockContactSupport();
  mockUserPolicies();
  mockSignPolicy();
  mockClientConfig();
  mockGetPatientClinicalInfoForm();
  mockSubmitPatientClinicalInfoForm();
};
