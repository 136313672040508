export const AC_AUDIT_LOG_FF = 'ac.auditLogEnabled';

export const getFFValue = (
  ffName: string,
  enabled: boolean = false
): boolean => {
  if (enabled) {
    return true;
  }

  const cookies = document?.cookie;
  const cookieArray = cookies?.split('; ');

  for (const cookie of cookieArray) {
    const [name, value] = cookie?.split('=');

    if (name === ffName) {
      return value === 'true';
    }
  }

  return false;
};
