import { getBaseColor, getSize, Menu } from '@viz/design-system';
import styled from 'styled-components';

export const StyledActionsMenu = styled(Menu)`
  .MuiPaper-root {
    background-color: ${getBaseColor('blue-zodiac')};

    ul {
      padding: 0;
    }

    .MuiMenuItem-root {
      display: flex;
      gap: ${getSize(2)};
      height: 26px;
      padding: ${getSize(1)} ${getSize(2)};
      font-size: 12px;
      color: ${getBaseColor('french-gray')};

      path {
        fill: ${getBaseColor('french-gray')};
      }

      &:hover {
        background-color: ${getBaseColor('rhino-blue')};
        color: ${getBaseColor('white')};

        path {
          fill: ${getBaseColor('white')};
        }
      }
    }
  }
`;
