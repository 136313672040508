import { ClinicalInfoItem } from '@viz/api';
import { localize } from '@viz/i18n';

import { timestampToDatetime } from '../utils';
import {
  StyledTitle,
  StyledTypography,
  StyledClinicalInfoSectionItemContainer
} from './styles';

interface ClinicalInfoItemProps {
  item: ClinicalInfoItem;
}

export const ClinicalInfoSectionItem = ({ item }: ClinicalInfoItemProps) => (
  <StyledClinicalInfoSectionItemContainer>
    <StyledTitle>{localize(item.title)}</StyledTitle>
    <StyledTypography fontSize="base">
      {item.type === 'timestamp' ? timestampToDatetime(item.value) : item.value}
    </StyledTypography>
  </StyledClinicalInfoSectionItemContainer>
);
