import {
  IconSizes,
  Loading,
  EllipsisTextTooltip,
  useInfiniteScroll
} from '@viz/design-system';
import { StyledLoaderContainer } from '@viz/design-system/src/ui/Table/styles';
import { localize } from '@viz/i18n';
import { formatAge, getLastUpdated, sortBy } from '@viz/utils';
import React from 'react';

import { SubHeader } from '../../../components/SubHeader';
import { PatientIcons } from '../PatientIcons';
import { PatientsCardsProps } from '../types';
import {
  StyledPatientsCards,
  StyledLoader,
  StyledPatientCard,
  StyledCardActions
} from './styles';

export const PatientsCards = ({
  patients,
  infiniteScrollProps,
  selectedPatientId,
  onCardClick,
  onCardDoubleClick,
  onMessageIconClick
}: PatientsCardsProps) => {
  const { targetRef } = useInfiniteScroll<HTMLDivElement>(infiniteScrollProps);

  return (
    <StyledPatientsCards>
      {patients.map(({ id, ...patient }) => {
        const sortedStudies = sortBy(
          patient.studies,
          'firstAcquiredTs',
          'desc'
        );
        const latestStudyTs = sortedStudies[0]?.firstAcquiredTs;
        const studyDate = getLastUpdated(latestStudyTs);
        return (
          <StyledPatientCard
            key={id}
            id={id}
            action={<b>{studyDate}</b>}
            title={<EllipsisTextTooltip>{patient.name}</EllipsisTextTooltip>}
            subheader={
              <SubHeader
                data={{
                  age: formatAge(patient.age),
                  sex: localize(patient.sex),
                  medicalRecordNumber: `MRN ${patient.medicalRecordNumber}`,
                  institutionName: patient.institutionName
                }}
              ></SubHeader>
            }
            selected={selectedPatientId === id}
            onClick={() => onCardClick && onCardClick(id, patient)}
            onDoubleClick={() =>
              onCardDoubleClick && onCardDoubleClick(id, patient)
            }
          >
            <StyledCardActions>
              <PatientIcons
                highlightIcons={patient.highlightIcons}
                messagesCount={patient.messagesCount}
                unreadMessagesCount={patient.unreadMessagesCount}
                isFailed={patient.studies.some((study) => study.isFailed)}
                onMessageIconClick={() => onMessageIconClick(id)}
              />
            </StyledCardActions>
          </StyledPatientCard>
        );
      })}
      {infiniteScrollProps?.hasNext && (
        <StyledLoader ref={targetRef}>
          <StyledLoaderContainer>
            {infiniteScrollProps?.loader ?? (
              <Loading size={IconSizes.X_MEDIUM} />
            )}
          </StyledLoaderContainer>
        </StyledLoader>
      )}
    </StyledPatientsCards>
  );
};
