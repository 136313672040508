import { getBaseColor, getSize } from '@viz/design-system';
import styled from 'styled-components';

import { FeedbackDialog } from '../../../../components';
import { Actions, PersonalDetails } from '../sections';
import { RegionInput } from '../sections/assignments/components';
import CreationOption from '../sections/creationOptions/CreationOptions';
import UserLogs from '../sections/userLogs/UserLogs';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  padding-left: ${getSize(6)};
  padding-bottom: ${getSize(4.5)};
  width: ${getSize(260)};
`;

export const StylesRegionInput = styled(RegionInput)`
  margin-bottom: ${getSize(16)};
`;

export const LoadingContainer = styled.div`
  width: ${getSize(260)};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledPersonalDetails = styled(PersonalDetails)`
  padding-bottom: ${getSize(8)};
  border-bottom: 1px solid ${getBaseColor('white', 0.1)};
`;

export const StyledCreationOptions = styled(CreationOption)`
  margin-top: ${getSize(8)};
  border-top: 1px solid ${getBaseColor('white', 0.1)};
`;

export const StyledUserLogs = styled(UserLogs)`
  margin-top: ${getSize(9)};
  border-top: 1px solid ${getBaseColor('white', 0.1)};
`;

export const StyledActions = styled(Actions)`
  margin-top: ${getSize(8)};
  border-top: 1px solid ${getBaseColor('white', 0.1)};
`;

export const DeactivateConfirmationDialog = styled(FeedbackDialog)`
  button[role='dialog-primary-button'] {
    background-color: ${getBaseColor('error')};
    border-color: ${getBaseColor('error')};
  }
`;
