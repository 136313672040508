import { useQueryClient } from '@tanstack/react-query';
import { UnsupportedViewportOverlay } from '@viz/design-system';
import { useEventListener } from '@viz/events';
import { History } from 'history';
import { useCallback, useState } from 'react';
import { Router as BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import {
  NotificationsCenter,
  OnlineStatusWatcher,
  ServiceWorkerWatcher,
  SessionWatcher
} from '../components';
import { Events } from '../events';
import {
  UnsupportedViewportOverlayContext,
  useWidgetAnalyticsEvent
} from '../hooks';
import { AppRouter } from '../navigation';
import { routes } from '../navigation/routes';
import { PatientData } from '../pages';
import { StyledApp } from './styles';

interface AppProps {
  history: History;
}

export const App = ({ history }: AppProps): JSX.Element => {
  const [recoilStateKey, setRecoilStateKey] = useState(0);
  const queryClient = useQueryClient();
  const [isOverlayEnabled, setIsOverlayEnabled] = useState(true);

  const resetState = useCallback(() => {
    setRecoilStateKey((prevKey) => prevKey + 1);
    queryClient.resetQueries();
  }, [queryClient]);

  useEventListener(Events.RESET_STATE, resetState);

  useWidgetAnalyticsEvent();

  return (
    <RecoilRoot key={recoilStateKey}>
      <BrowserRouter history={history}>
        <UnsupportedViewportOverlayContext.Provider
          value={{ isOverlayEnabled, setIsOverlayEnabled }}
        >
          <StyledApp>
            <PatientData />
            <AppRouter routes={routes} />
            <SessionWatcher />
            <OnlineStatusWatcher />
            <ServiceWorkerWatcher />
            <NotificationsCenter />
            <UnsupportedViewportOverlay
              enabled={isOverlayEnabled}
              minWidth={window.vizWidget ? 320 : 0}
              minHeight={0}
            />
          </StyledApp>
        </UnsupportedViewportOverlayContext.Provider>
      </BrowserRouter>
    </RecoilRoot>
  );
};
