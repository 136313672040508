import { FC } from 'react';

import { StyledVisibilityContainer } from './styles';

type WithVisibility<P> = P & {
  isVisible?: boolean;
};

const withVisibility = <P,>(InnerComponent: FC<P>): FC<WithVisibility<P>> => {
  return ({ isVisible = true, ...props }: WithVisibility<P>): JSX.Element => {
    return (
      <StyledVisibilityContainer $isVisible={isVisible}>
        <InnerComponent {...(props as P)} />
      </StyledVisibilityContainer>
    );
  };
};

export { withVisibility };
