export enum AnalyticsEventName {
  LVO_SUSPECTED = 'lvo_suspected',
  ICH_SUSPECTED = 'ich_suspected',
  PATIENT_LIST_VIEWED = 'patient_list_viewed',
  PATIENT_OVERVIEW_VIEWED = 'patient_overview_viewed',
  PATIENT_SEARCH_CRITERIA_SUBMITTED = 'patient_search_criteria_submitted',
  PATIENT_CLINICAL_INFO_SUBMITTED_SUCCESS = 'patient_clinical_info_add_successful',
  PATIENT_CLINICAL_INFO_SUBMITTED_FAILED = 'patient_clinical_info_add_failed',
  PATIENT_PACS_LINK_CLICKED = 'patient_pacs_link_clicked',
  LOGIN_SCREEN_VIEWED = 'login_screen_viewed',
  LOGIN_BUTTON_CLICKED = 'login_next_button_clicked',
  LOGIN_SUCCESSFUL = 'login_successful',
  LOGIN_FAILED = 'login_failed',
  RESET_PASSWORD_BUTTON_CLICKED = 'reset_password_button_clicked',
  PRODUCT_USER_MANUAL_VIEWED = 'product_user_manual_viewed',
  PRODUCT_LEGAL_SECTION_VIEWED = 'product_legal_section_viewed',
  CHAT_MESSAGE_SENT = 'chat_message_sent',
  PATIENT_CHAT_VIEWED = 'patient_chat_viewed',
  PUSH_NOTIFICATION_RECEIVED = 'push_notification_received',
  PUSH_NOTIFICATION_CLICKED = 'push_notification_clicked',
  PATIENT_SERIES_VIEWED = 'patient_series_viewed',
  PATIENT_SHARED = 'patient_shared',
  SHARE_PATIENT_DIALOG_VIEWED = 'share_patient_dialog_viewed',
  PATIENT_STATUS_UPDATED = 'patient_status_updated',
  PATIENT_LIST_BULK_UPDATE = 'patient_list_bulk_update',
  PATIENT_SERIES_LOADING_COMPLETED = 'patient_series_loading_completed',
  PATIENT_LIST_FILTERED = 'patient_list_filtered',
  PATIENT_LIST_SORT = 'patient_list_sort',
  VIEWER_MIP_SETTINGS_VIEWED = 'viewer_mip_settings_viewed',
  VIEWER_MIP_SETTINGS_UPDATED = 'viewer_mip_settings_updated',
  VIEWER_SLICE_SCROLL_BAR_POSITION_UPDATED = 'viewer_slice_scroll_bar_position_updated',
  VIEWER_WINDOW_MANUALLY_UPDATED = 'viewer_window_manually_updated',
  VIEWER_WINDOW_PRESET_CLICKED = 'viewer_window_preset_clicked',
  VIEWER_ORIENTATION_BUTTON_CLICKED = 'viewer_orientation_button_clicked',
  VIEWER_ORIENTATION_UPDATED = 'viewer_orientation_updated',
  VIEWER_ROTATE_BUTTON_CLICKED = 'viewer_rotate_button_clicked',
  MINIMIZED_TO_PATIENT_LIST = 'minimized_to_patient_list',
  VIEWER_SWITCH_DIMENSION_CLICKED = 'viewer_switch_dimension_clicked',
  PATIENT_ECG_VIEWED = 'patient_ecg_viewed',
  COPY_MRN_BUTTON_CLICKED_SUCCESSFUL = 'copy_mrn_button_clicked_successful',
  COPY_MRN_BUTTON_CLICKED_FAILED = 'copy_mrn_button_clicked_failed',

  // Settings Events
  USER_DROP_MENU_VIEWED = 'user_drop_menu_viewed',
  ON_CALL_STATUS_UPDATED = 'on_call_status_updated',
  DOCUMENTATION_SECTION_VIEWED = 'documentation_section_viewed',
  APP_SETTINGS_VIEWED = 'app_settings_viewed',
  INSTITUTION_FILTER_SETTINGS_VIEWED = 'institution_filter_settings_viewed',
  INSTITUTION_FILTER_CHECK_ALL_CLICKED = 'institution_filter_check_all_clicked',
  INSTITUTION_FILTER_UNCHECK_ALL_CLICKED = 'institution_filter_uncheck_all_clicked',
  INSTITUTION_FILTER_ITEM_MANUALLY_CHECKED = 'institution_filter_item_manually_checked',
  INSTITUTION_FILTER_ITEM_MANUALLY_UNCHECKED = 'institution_filter_item_manually_unchecked',
  NOTIFICATION_SETTINGS_VIEWED = 'notification_settings_viewed',
  NOTIFICATION_SETTINGS_UPDATED = 'notification_settings_updated',
  NOTIFICATION_SETTINGS_RESET_CLICKED = 'notification_settings_reset_clicked',
  PHONE_NUMBER_SETTINGS_CLICKED = 'phone_number_settings_clicked',
  PHONE_NUMBER_SETTINGS_UPDATED = 'phone_number_settings_updated',
  CONTACT_SUPPORT_FORM_SUBMITTED = 'contact_support_form_submitted',
  CONTACT_SUPPORT_FORM_VIEWED = 'contact_support_form_viewed',
  APP_SETTINGS_CONTACT_SUPPORT_CLICKED = 'app_settings_contact_support_clicked',

  // Admin Events
  ADMIN_ACCESS_SUCCESSFUL = 'admin_access_successful',
  ADMIN_EDIT_USER_STARTS = 'admin_edit_user_starts',
  ADMIN_EDIT_USER_SUBMITTED = 'admin_edit_user_submitted',
  ADMIN_EDIT_USER_STOPPED = 'admin_edit_user_stopped',
  ADMIN_SEARCH_REQUEST_SENT = 'admin_search_request_sent',
  ADMIN_ADD_USER_START = 'admin_add_user_start',
  ADMIN_ADD_USER_SUBMITTED = 'admin_add_user_submitted',
  ADMIN_ADD_USER_STOPPED = 'admin_add_user_stopped',
  ADMIN_USER_DELETED_SUCCESSFUL = 'admin_user_deleted',
  ADMIN_INVITATION_EMAIL_SENT = 'admin_invitation_email_sent',
  ADMIN_INVITATION_EMAIL_RESPONSE = 'admin_invitation_email_response'
}

export const enum ViewerType {
  CT = 'ct',
  ECG = 'ecg'
}

export const enum AnalyticsEventMethod {
  LOGIN = 'basic_login',
  RESET_PASSWORD = 'reset_password',
  ON_DOCUMENT_CLICK = 'on_document_click',
  START_SHIFT = 'start_shift',
  END_SHIFT = 'end_shift'
}

export const enum PatientType {
  PATIENT = 'patient',
  PAGER = 'pager'
}

export const enum ChatType {
  PATIENT_CHAT = 'patient_chat',
  GROUP_CHAT = 'group_chat',
  DIRECT_MESSAGE = 'direct_message'
}

export const enum MessageAttachmentType {
  NONE = 'none',
  PHOTO = 'photo'
}

// Admin Types
export const enum EditUserType {
  DIRECT_EDIT = 'direct_edit'
}

export const enum AdminAccessType {
  URL_ACCESS = 'url_access',
  LINK_ACCESS = 'link_access'
}
