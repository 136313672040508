import { Patient, Study, Ecg } from '@viz/api';
import { Tabs, TabType } from '@viz/design-system';
import { localize } from '@viz/i18n';
import { isAfter, isBefore, subHours } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { useRouter } from '../../../hooks';
import { UseRouter } from '../../../hooks/useRouter/useRouter';
import { browserStorage } from '../../../managers';
import {
  patientFolderSelectedTabStateFamily,
  PatientFolderTabs
} from '../../../store';
import { PRIOR_HOURS } from '../../../types';
import { ClinicalInfo } from './ClinicalInfo';
import { PatientChat } from './PatientChat';
import { PatientStudies } from './PatientStudies';
import { PreScreening } from './PreScreening';
import { StyledClinicalDetails, StyledTabContainer } from './styles';
import { PatientChannels } from './PatientChannels';

const DEFAULT_TABS = [
  PatientFolderTabs.CURRENT_IMAGING,
  PatientFolderTabs.PRIOR_EXAMS,
  PatientFolderTabs.CHAT,
  PatientFolderTabs.CLINICAL_INFO
];

const PRE_SCREENING_TABS = [
  PatientFolderTabs.PRE_SCREENING,
  PatientFolderTabs.CHAT
];

const isNewStudies = (exams: (Study | Ecg)[]): boolean =>
  exams?.some((exam) =>
    isAfter(exam.lastArrivalTs, subHours(new Date(), PRIOR_HOURS))
  );

const shouldOpenCurrentImagingTab = (
  studyUid?: string,
  exams?: (Study | Ecg)[]
): boolean => {
  if (!exams || exams.length === 0) return true;
  if (!studyUid) return isNewStudies(exams);

  const exam = exams?.find((exam) => exam.uid === studyUid);
  return !!exam && isNewStudies([exam]);
};

const filterStudies = (exams: Array<Study | Ecg>, isPrior: boolean) => {
  return exams.filter((exam) =>
    (isPrior ? isBefore : isAfter)(
      exam.lastArrivalTs,
      subHours(new Date(), PRIOR_HOURS)
    )
  );
};

const getActiveStudyUid = (router: UseRouter) => {
  const studyIdUrlParam = router.getUrlParam('study_id') as string;
  const ecgIdUrlParam = router.getUrlParam('ecg_id') as string;
  const activeSeriesUid = router.getUrlParam('series_id') as string;
  return { activeStudyUid: studyIdUrlParam || ecgIdUrlParam, activeSeriesUid };
};

export type ClinicalDetailsProps = {
  patient: Patient;
  enableEditClinicalInfo: boolean;
  onChangeClinicalInfo: () => void;
  patientTabId?: number;
};

const ClinicalDetails = (props: ClinicalDetailsProps): JSX.Element => {
  const { patient } = props;
  const { id, studies, ecgs } = patient;

  const router = useRouter();
  const { activeStudyUid, activeSeriesUid } = getActiveStudyUid(router);
  const clientConfig = browserStorage.clientConfig.get();
  const userChatAccess = browserStorage.userChatAccess.get();
  const shouldDisplayNewChat = Boolean(userChatAccess?.patient_enabled)
  console.log({shouldDisplayNewChat})
  const exams = useMemo(
    () =>
      [...studies, ...ecgs].sort((a, b) => a.lastArrivalTs - b.lastArrivalTs),
    [studies, ecgs]
  );
  const [currentStudies, priorStudies] = useMemo(
    () => [filterStudies(exams, false), filterStudies(exams, true)],
    [exams]
  );

  const [selectedTab, setSelectedTab] = useRecoilState(
    patientFolderSelectedTabStateFamily(id)
  );

  const updateTabByActiveStudy = useCallback(() => {
    if (selectedTab) return;

    if (clientConfig.enable_novo_trial_state) {
      setSelectedTab(PatientFolderTabs.PRE_SCREENING);
    } else {
      const activeTab = shouldOpenCurrentImagingTab(activeStudyUid, exams)
        ? PatientFolderTabs.CURRENT_IMAGING
        : PatientFolderTabs.PRIOR_EXAMS;

      setSelectedTab(activeTab);
    }
  }, [activeStudyUid, selectedTab, setSelectedTab, exams, clientConfig]);

  useEffect(updateTabByActiveStudy, [updateTabByActiveStudy]);

  const patientClinicalDetailsTabs = useMemo(() => {
    const tabs = clientConfig.enable_novo_trial_state
      ? PRE_SCREENING_TABS
      : DEFAULT_TABS;
    return tabs.map((tab) => {
      const updatedTab = tab === PatientFolderTabs.CHAT && shouldDisplayNewChat ? PatientFolderTabs.CHANNELS : tab
      return {
        label: localize(updatedTab),
        value: updatedTab,
        onClick: () => setSelectedTab(updatedTab)
      }
    });
  }, [setSelectedTab, clientConfig, shouldDisplayNewChat]);

  const isTabSelected = (tabId: PatientFolderTabs): boolean =>
    selectedTab === tabId;

  const displayedExams =
    selectedTab === PatientFolderTabs.PRIOR_EXAMS
      ? priorStudies
      : currentStudies;

  return (
    <StyledClinicalDetails>
      <Tabs
        tabs={patientClinicalDetailsTabs}
        tabType={TabType.DEFAULT}
        activeTabId={selectedTab}
        testId="patient-folder-tabs"
      />
      <StyledTabContainer>
        <PatientStudies
          exams={displayedExams}
          patient={patient}
          activeStudyUid={
            displayedExams.find((exam) => exam.uid === activeStudyUid)?.uid
          }
          activeSeriesUid={activeSeriesUid}
          isVisible={
            isTabSelected(PatientFolderTabs.CURRENT_IMAGING) ||
            isTabSelected(PatientFolderTabs.PRIOR_EXAMS)
          }
        />
        <ClinicalInfo
          isVisible={isTabSelected(PatientFolderTabs.CLINICAL_INFO)}
          onChangePatientClinicalInfo={props.onChangeClinicalInfo}
          enableEdit={props.enableEditClinicalInfo}
          patientTabId={props.patientTabId}
          patient={patient}
        />
        {shouldDisplayNewChat ?
          <PatientChannels
           patient={patient}
           apiKey={userChatAccess.api_key}
           token={userChatAccess.token}
          /> :
          <PatientChat
          patient={patient}
          key={id}
          isVisible={isTabSelected(PatientFolderTabs.CHAT)}
          shouldRefetch={isTabSelected(PatientFolderTabs.CHAT)}
        />}
        {clientConfig.enable_novo_trial_state === true && (
          <PreScreening
            patient={patient}
            isVisible={isTabSelected(PatientFolderTabs.PRE_SCREENING)}
          />
        )}
      </StyledTabContainer>
    </StyledClinicalDetails>
  );
};

export { ClinicalDetails };
