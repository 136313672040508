import { Badge, IconInfoError, IconMessage } from '@viz/design-system';
import React from 'react';

import { AlgoDetectionIcon } from '../../../components';
import {
  StyledIconButton,
  StyledPatientIconsContainer,
  StyledIconContainer
} from './styles';

export interface PatientIconsProps {
  highlightIcons: string[];
  messagesCount: number;
  unreadMessagesCount: number;
  isFailed: boolean;
  onMessageIconClick: () => void;
}

const MAX_COUNT = 99;

export const PatientIcons = ({
  highlightIcons,
  messagesCount,
  unreadMessagesCount,
  isFailed,
  onMessageIconClick
}: PatientIconsProps): JSX.Element => (
  <StyledPatientIconsContainer>
    {highlightIcons.map((iconUrl, index) => (
      <StyledIconContainer data-testid="test-icon" key={index}>
        <AlgoDetectionIcon iconUrl={iconUrl} />
      </StyledIconContainer>
    ))}
    {/*if there are highlightIcons and failure, there will already be an error icon*/}
    {!highlightIcons.length && isFailed && (
      <IconInfoError data-testid="error" />
    )}
    {messagesCount > 0 && (
      <StyledIconContainer data-testid="test-icon">
        <Badge
          testId="patient-messages-badge"
          badgeContent={unreadMessagesCount}
          max={MAX_COUNT}
          color="primary"
        >
          <StyledIconButton onClick={onMessageIconClick} data-testid="chat-btn">
            <IconMessage />
          </StyledIconButton>
        </Badge>
      </StyledIconContainer>
    )}
  </StyledPatientIconsContainer>
);
