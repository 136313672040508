import { ClientConfigTabFilter } from '@viz/api';
import {
  DateRangePicker,
  Filter,
  FilterMenuItem,
  IconSizes
} from '@viz/design-system';
import { useClickOutside } from '@viz/hooks/src/useCLickOutside';
import { format } from 'date-fns';
import {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import {
  StyledDateRangePickerContainer,
  StyledFilterContainer,
  StyledIconArrow
} from '../styles';
import { FilterSelection, FilterValue } from '../types';

interface PatientsFilterProps {
  filterConfig: ClientConfigTabFilter;
  onChange: (filterSelection: FilterSelection) => void;
  filterValue?: FilterValue | undefined;
}

enum CustomFilterType {
  DateRange = 'date_range'
}

const datePickerOptionClass = 'date-picker-option';

const PatientsFilter = ({
  filterConfig,
  onChange,
  filterValue
}: PatientsFilterProps) => {
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [datePickerOptionWidth, setDatePickerOptionWidth] = useState(200);
  const datePickerMenuItemRef = useRef<HTMLLIElement>(null);
  const value = useMemo(() => {
    const selectedName = filterValue?.name;
    const selectedParams = filterValue?.params;
    return selectedParams && selectedName === CustomFilterType.DateRange
      ? `${
          selectedParams?.start_time &&
          format(selectedParams?.start_time, 'MMM dd, yyyy')
        } - ${
          selectedParams?.end_time &&
          format(selectedParams?.end_time, 'MMM dd, yyyy')
        }`
      : filterConfig.options.find(({ name }) => name === selectedName)
          ?.display_name;
  }, [filterConfig, filterValue]);

  useEffect(() => {
    if (isFilterOpen && datePickerMenuItemRef.current) {
      setDatePickerOptionWidth(datePickerMenuItemRef.current.offsetWidth);
    }
  }, [isFilterOpen]);

  const handleOptionChange = (event: ChangeEvent<{ value: unknown }>) => {
    const displayName = event.target.value as string;
    const name = filterConfig.options.find(
      (option) => option.display_name === displayName
    )?.name;

    if (name === CustomFilterType.DateRange) {
      return;
    }

    onChange({ name: filterConfig.name, option: name ? { name } : undefined });
  };

  const handleClear = () => {
    closeDatePicker();
    onChange({ name: filterConfig.name, option: undefined });
  };

  const closeDatePicker = () => {
    setIsDatePickerVisible(false);
    setIsFilterOpen(false);
  };

  const showDatePicker = () => {
    setIsDatePickerVisible(true);
    setIsFilterOpen(true);
  };

  const handleDateChange = (start: number | null, end: number | null) => {
    const customFilter: FilterValue = {
      name: 'date_range',
      params: {
        start_time: start,
        end_time: end
      }
    };
    onChange({ name: filterConfig.name, option: customFilter });
    closeDatePicker();
  };

  const handleDatePickerOptionClick = (e: SyntheticEvent) => {
    const target = e.nativeEvent.target as HTMLElement;
    if (
      target.classList.contains(datePickerOptionClass) ||
      target.querySelector('.date-picker-option')
    ) {
      e.preventDefault();
      showDatePicker();
    }
  };

  const handleDatePickerOutsideClick = () => {
    closeDatePicker();
  };

  const datePickerRef = useClickOutside<HTMLDivElement>(
    handleDatePickerOutsideClick
  );

  return (
    <StyledFilterContainer onClick={handleDatePickerOptionClick}>
      <Filter
        dataTestId={`filter-${filterConfig.name}`}
        onChange={handleOptionChange}
        onClear={handleClear}
        isOpen={isFilterOpen}
        value={value}
        valueLabel={filterConfig.display_name}
      >
        {filterConfig.options.map(({ name, display_name }) =>
          name === CustomFilterType.DateRange ? (
            <FilterMenuItem
              key={name}
              value={display_name}
              ref={datePickerMenuItemRef}
            >
              <span className={datePickerOptionClass}>
                {display_name}
                <StyledIconArrow size={IconSizes.XX_SMALL} />
              </span>
            </FilterMenuItem>
          ) : (
            <FilterMenuItem key={name} value={display_name}>
              {display_name}
            </FilterMenuItem>
          )
        )}
      </Filter>
      {isDatePickerVisible && (
        <StyledDateRangePickerContainer
          ref={datePickerRef}
          style={{ left: datePickerOptionWidth }}
        >
          <DateRangePicker
            onCancel={closeDatePicker}
            start={filterValue?.params?.start_time}
            end={filterValue?.params?.end_time}
            onApply={handleDateChange}
          />
        </StyledDateRangePickerContainer>
      )}
    </StyledFilterContainer>
  );
};

export { PatientsFilter };
