import { Patient } from '@viz/api';
import {
  ChatContainer,
  IconSizes,
  Loading,
  StudyReferralDetails
} from '@viz/design-system';
import { PushNotificationType } from '@viz/hooks';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { withVisibility } from '../../../../hocs';
import {
  AnalyticsEventName,
  ChatType,
  useAnalyticsEvent,
  useUserProfile,
  useChatConversation
} from '../../../../hooks';
import { getPatientTabState, patientTabDialogState } from '../../../../store';
import { AcceptReferralDialog } from './AcceptReferralDialog';
import { StyledPatientChatContainer } from './styles';

const REFETCH_INTERVAL = 5000;

export type PatientChatProps = {
  patient: Patient;
  shouldRefetch?: boolean;
  allowSpecialMessages?: boolean;
};

const PatientChat = ({
  patient,
  shouldRefetch = true,
  allowSpecialMessages = true
}: PatientChatProps): JSX.Element => {
  const setPatientTabDialogData = useSetRecoilState(patientTabDialogState);
  const isPatientTabOpen = useRecoilValue(getPatientTabState(patient.id));

  const { user } = useUserProfile();
  const { sendEvent } = useAnalyticsEvent();
  const [acceptReferralId, setAcceptReferralId] = useState('');
  const {
    messages,
    isLoading,
    participants,
    unreadMessagesAmount,
    sendNewMessage,
    retrySendMessage,
    acceptReferral
  } = useChatConversation({
    conversationId: patient.id,
    messageType: PushNotificationType.MESSAGE,
    refetchInterval: shouldRefetch ? REFETCH_INTERVAL : undefined,
    chatType: ChatType.PATIENT_CHAT,
    allowSpecialMessages
  });

  useEffect(() => {
    if (shouldRefetch) {
      sendEvent(AnalyticsEventName.PATIENT_CHAT_VIEWED, {
        patient_id: patient.id,
        di_patient_name: patient.diName,
        di_patient_mrn: patient.diMedicalRecordNumber,
        institution: patient.institutionName,
        chat_id: patient.id,
        is_muted: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetch, patient.id]);

  const onMessageClick = (details: Partial<StudyReferralDetails>) => {
    if (!isPatientTabOpen || (details.studyUid && details.seriesUid)) {
      setPatientTabDialogData({
        tabUrlData: {
          id: details.patientId!,
          study_id: details.studyUid,
          series_id: details.seriesUid
        },
        patientName: patient.name
      });
    }
  };

  return (
    <>
      <StyledPatientChatContainer>
        {isLoading ? (
          <Loading size={IconSizes.LARGE} testId="patient-chat-loader" />
        ) : (
          <ChatContainer
            messages={messages}
            participants={participants}
            unreadMessagesAmount={unreadMessagesAmount}
            authorId={user?.user_uid || ''}
            onMessageClick={onMessageClick}
            onSend={sendNewMessage}
            testId="patient-chat"
            onRetrySend={retrySendMessage}
            onAcceptReferral={(referralId) => setAcceptReferralId(referralId)}
          />
        )}
      </StyledPatientChatContainer>
      <AcceptReferralDialog
        open={Boolean(acceptReferralId)}
        onAccept={() => acceptReferral(acceptReferralId)}
        onClose={() => setAcceptReferralId('')}
      />
    </>
  );
};

export default withVisibility(PatientChat);
