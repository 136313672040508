import {
  ChatMessage,
  useAcceptReferral,
  useAckMessages,
  useShiftStatus,
  useUnreadMessagesAmount
} from '@viz/api';
import { useConversation, useSendNewMessage } from '@viz/api/src/chat/hooks';
import {
  ChatMessageDetails,
  MessageContent,
  MessageSender,
  MessageStatus
} from '@viz/design-system';
import { PushNotificationType, VizFirebaseNotification } from '@viz/hooks';
import { useEffect, useMemo, useState } from 'react';

import { Subscriber } from '../../managers';
import {
  AnalyticsEventName,
  ChatType,
  MessageAttachmentType,
  useAnalyticsEvent
} from '../useAnalytics';
import { useNotificationSubscriber } from '../useNotificationSubscriber';
import { useUserProfile } from '../useUserProfile';
import { ChatNotification } from './types';
import {
  changePendingMessageStatus,
  createMessagePayload,
  createPendingMessage,
  getNewMessages,
  getUpdatedPendingMessages,
  mapServerMessageToChatMessage,
  mapServerSenderToChatMessageSender,
  PendingMessage,
  setAcceptedReferral
} from './utils';

const MESSAGE_HISTORY_BUFFER = 5;

export type UseChatConversationOptions = {
  conversationId: string;
  refetchInterval?: number;
  chatType: ChatType;
  messageType?:
    | PushNotificationType.MESSAGE
    | PushNotificationType.GROUP_MESSAGE;
  allowSpecialMessages?: boolean;
};

const useChatConversation = ({
  conversationId,
  refetchInterval,
  chatType,
  messageType = PushNotificationType.MESSAGE,
  allowSpecialMessages = true
}: UseChatConversationOptions) => {
  const { user } = useUserProfile();
  const { sendEvent } = useAnalyticsEvent();
  const { data: onShiftData } = useShiftStatus({ refetchOnWindowFocus: false });
  const { mutate: ackMessagesMutation } = useAckMessages(conversationId);
  const { mutate: acceptReferralMutation } = useAcceptReferral();
  const [messages, setMessages] = useState<ChatMessageDetails[]>([]);
  const [participants, setParticipants] = useState<MessageSender[]>([]);
  const [unreadMessagesAmount, setUnreadMessagesAmount] = useState(0);
  const [showUnreadMessagesIndicator, setShowUnreadMessagesIndicator] =
    useState(true);
  const [isRefetch, setIsRefetch] = useState(false);
  const [pendingMessages, setPendingMessages] = useState<PendingMessage[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const isChatVisible = Boolean(refetchInterval);
  const isOnCall = onShiftData && onShiftData.on_shift;
  const shouldRefetch = isChatVisible && !isOnCall;

  const [historySize, setHistorySize] = useState(0);

  const unreadMessagesQuery = useUnreadMessagesAmount(
    { conversationId },
    {
      enabled: Boolean(conversationId && isChatVisible),
      refetchOnMount: false,
      refetchInterval: shouldRefetch && refetchInterval,
      onSuccess: ({ unread_comments_amount }) =>
        checkUnreadMessagesAmount(unread_comments_amount)
    }
  );

  useConversation(
    { conversationId, historySize },
    {
      enabled: Boolean(conversationId),
      refetchOnWindowFocus: false,
      onSuccess: ({ conversation }) => {
        const { messages, unreadAmount, participants } = conversation;

        setParticipants(participants.map(mapServerSenderToChatMessageSender));

        setUnreadMessagesAmount(unreadAmount);
        setShowUnreadMessagesIndicator(!isRefetch);
        setIsRefetch(isChatVisible);

        updateMessages(messages);
        acknowledgeMessages(messages, unreadAmount);
      },
      onError: () => {
        setHistorySize(0);
      }
    }
  );

  const onMessageError = (messageId: string) => {
    setPendingMessages((pendingMessages) => {
      return changePendingMessageStatus(
        pendingMessages,
        messageId,
        MessageStatus.FAILED
      );
    });
  };

  const { sendMessage } = useSendNewMessage(onMessageError);

  const onChatNotification: Subscriber<VizFirebaseNotification> = (
    notification
  ) => {
    const { conversation_id: notificationConversationId } =
      notification as ChatNotification;
    if (notificationConversationId === conversationId) {
      unreadMessagesQuery.refetch();
    }
  };

  useNotificationSubscriber({
    subject: messageType,
    onNotification: onChatNotification
  });

  useNotificationSubscriber({
    subject: PushNotificationType.PATIENT_SHARED,
    onNotification: onChatNotification
  });

  useEffect(() => {
    setShowUnreadMessagesIndicator(true);
    setIsRefetch(false);

    if (!isChatVisible) {
      setUnreadMessagesAmount(0);
    }
  }, [isChatVisible]);

  useEffect(() => {
    pendingMessages.forEach((message) => {
      if (message.delivered === MessageStatus.UNSENT) {
        sendMessage(createMessagePayload(message, conversationId));
        setPendingMessages((pendingMessages) => {
          return changePendingMessageStatus(
            pendingMessages,
            message.id,
            MessageStatus.PENDING
          );
        });
      }
    });
  }, [conversationId, pendingMessages, sendMessage]);

  const checkUnreadMessagesAmount = (amount: number) => {
    if (amount > 0) {
      const updatedAmount =
        amount + pendingMessages.length + MESSAGE_HISTORY_BUFFER;

      setHistorySize((prev) => {
        return prev === updatedAmount ? updatedAmount + 1 : updatedAmount;
      });
    } else {
      setIsRefetch(true);
    }
  };

  const updateMessages = async (incomingMessages: ChatMessage[]) => {
    const mappedIncomingMessages = await Promise.all(
      incomingMessages.map(mapServerMessageToChatMessage)
    );

    setMessages((existingMessages) => {
      const newMessages = getNewMessages(
        existingMessages,
        mappedIncomingMessages
      );

      return existingMessages.concat(newMessages);
    });

    setPendingMessages((prevPendingMessages) =>
      getUpdatedPendingMessages(prevPendingMessages, mappedIncomingMessages)
    );

    if (isLoading) {
      setIsLoading(false);
    }
  };

  const acknowledgeMessages = (
    messages: ChatMessage[],
    unreadAmount: number
  ) => {
    if (
      unreadAmount > 0 &&
      (isChatVisible || Boolean(messages[messages.length - 1].read))
    ) {
      const unreadMessageIds = messages
        .filter(({ read }) => !read)
        .map(({ id }) => id);
      ackMessagesMutation({
        messageIds: unreadMessageIds
      });
    }
  };

  const allMessages = useMemo(() => {
    const updatedPendingMessages = getUpdatedPendingMessages(
      pendingMessages,
      messages
    );

    const mergedMessages = [...messages, ...updatedPendingMessages];

    return allowSpecialMessages
      ? mergedMessages
      : mergedMessages.filter((message) => message.sharedItem === undefined);
  }, [messages, pendingMessages, allowSpecialMessages]);

  const sendNewMessage = ({ text, files }: MessageContent) => {
    setUnreadMessagesAmount(0);

    sendEvent(AnalyticsEventName.CHAT_MESSAGE_SENT, {
      chat_id: conversationId,
      chat_type: chatType,
      attachment_type:
        files.length > 0
          ? MessageAttachmentType.PHOTO
          : MessageAttachmentType.NONE
    });

    setPendingMessages((prevMessages = []) => [
      ...prevMessages,
      createPendingMessage(user!.user_uid, text, files)
    ]);
  };

  const retrySendMessage = (messageId: string) => {
    const failedMessage = pendingMessages.find(({ id }) => id === messageId);

    if (failedMessage) {
      sendMessage(createMessagePayload(failedMessage, conversationId));
      setPendingMessages((pendingMessages) => {
        return changePendingMessageStatus(
          pendingMessages,
          failedMessage.id,
          MessageStatus.PENDING
        );
      });
    }
  };

  const acceptReferral = (referralId: string) => {
    acceptReferralMutation(
      { referralId },
      {
        onSuccess: (data) => {
          setMessages((currentMessages) =>
            setAcceptedReferral(
              currentMessages,
              referralId,
              data.acceptReferral.metadata?.acceptedTs,
              data.acceptReferral.metadata?.acceptAction
            )
          );
        }
      }
    );
  };

  return {
    messages: allMessages,
    isLoading,
    unreadMessagesAmount: showUnreadMessagesIndicator
      ? unreadMessagesAmount
      : 0,
    participants,
    sendNewMessage,
    retrySendMessage,
    acceptReferral
  };
};

export { useChatConversation };
