import {
  Ecg,
  Patient,
  patientDataModification,
  Study,
  usePatient
} from '@viz/api';
import {
  getFontWeight,
  IconHome,
  IconPatient,
  IconSizes,
  TabMenuProps,
  TabName,
  Typography
} from '@viz/design-system';
import { logger } from '@viz/logging';
import { useEffect, useRef } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';

import { useAuth, useRouter } from '../../hooks';
import { Paths } from '../../navigation';
import {
  getPatientTabState,
  patientTabsState,
  PatientViewerData,
  patientViewerDataStateFamily,
  currentPatientListUrRelativePath,
  TabUrlData
} from '../../store';
import { getViewerDefaultData } from './utils';

const MAX_OPEN_TABS = 1;

type SelectTabParams = {
  tabUrlData?: TabUrlData;
  updatedTabs?: TabMenuProps.TabDataProps[];
  lastPatientListUrlRelativePath?: string | undefined;
};

export interface TabsHookDetails {
  tabs: TabMenuProps.TabDataProps[];
  isMaxTabsOpen: boolean;
  selectedTab?: string;
  selectTab: (selectTabParams: SelectTabParams) => boolean;
  closePatientTab: (
    patientIdToClose?: string,
    tabUrlData?: TabUrlData
  ) => Promise<void>;
}

const PatientTabContent = ({
  name,
  medicalRecordNumber
}: Pick<Patient, 'name' | 'medicalRecordNumber'>) => {
  return (
    <div data-testid={`patient-tab-${name}`}>
      <Typography fontWeight={getFontWeight('font-weight-bold-600')}>
        {name}
      </Typography>
      <Typography fontSize="xs">MRN {medicalRecordNumber}</Typography>
    </div>
  );
};

const usePatientTabs = (): TabsHookDetails => {
  const router = useRouter();
  const { isAuth } = useAuth();

  const patientId = router.getUrlParam('id') as string;
  const activeTabIdRef = useRef<string>('');

  const [currentTabs, setTabs] = useRecoilState(patientTabsState);
  const patientTab = useRecoilValue(getPatientTabState(patientId));
  const patientViewerData = useRecoilValue(
    patientViewerDataStateFamily(patientId)
  );

  const { data: patient } = usePatient(
    { enabled: Boolean(patientId && isAuth), select: patientDataModification },
    { patientId }
  );

  useEffect(() => {
    activeTabIdRef.current = patientId;
  }, [patientId]);

  useEffect(() => {
    if (patient) {
      if (shouldSelectViewerDefaultData()) {
        const { studyUid, seriesUid, ecgId } = getViewerData(
          patient.studies,
          patient.ecgs
        );

        const tabUrlData = {
          id: patient.id,
          study_id: studyUid,
          series_id: seriesUid,
          ecg_id: ecgId
        };
        selectTab({ tabUrlData });
      }

      if (!patientTab) {
        addPatientTab(patient);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  const shouldSelectViewerDefaultData = (): boolean => {
    const studyUid = router.getUrlParam('study_id') as string;
    const seriesUid = router.getUrlParam('series_id') as string;
    const ecgId = router.getUrlParam('ecg_id') as string;
    return !ecgId && (!seriesUid || !studyUid);
  };

  const getViewerData = (studies: Study[], ecgs: Ecg[]): PatientViewerData => {
    const isPatientViewerDataAvalible =
      (patientViewerData?.studyUid && patientViewerData?.seriesUid) ||
      patientViewerData?.ecgId;
    return isPatientViewerDataAvalible
      ? patientViewerData
      : getViewerDefaultData(studies, ecgs);
  };

  const selectTab = (selectTabParams?: SelectTabParams): boolean => {
    const tabUrlData = selectTabParams?.tabUrlData;
    const updatedTabs = selectTabParams?.updatedTabs;
    const lastPatientListUrlRelativePath =
      selectTabParams?.lastPatientListUrlRelativePath;
    const tabs = updatedTabs ?? currentTabs;

    if (!tabUrlData) {
      lastPatientListUrlRelativePath
        ? router.navigate({
            path: lastPatientListUrlRelativePath
          })
        : router.navigate({ path: Paths.CASES });
      return true;
    } else if (
      tabs.length < MAX_OPEN_TABS ||
      tabs.find(({ value }) => value === tabUrlData.id)
    ) {
      router.navigate({ path: Paths.PATIENT_DATA, queryParams: tabUrlData });
      return true;
    } else {
      return false;
    }
  };

  const addPatientTab = (
    patient: Pick<Patient, 'id' | 'name' | 'medicalRecordNumber'>
  ) => {
    const tab: TabMenuProps.TabDataProps = {
      value: patient.id,
      label: <PatientTabContent {...patient} />,
      icon: <IconPatient size={IconSizes.MEDIUM} />,
      onClick: () => {
        const tabUrlData = { id: patient.id };
        selectTab({ tabUrlData });
      },
      onClose: (event: React.ChangeEvent<{}>) => {
        event.stopPropagation();
        event.preventDefault();
        closePatientTab(patient.id).catch((e) => {
          logger.error('closePatientTab exception', e);
        });
      }
    };

    setTabs([...currentTabs, tab]);
  };

  const closePatientTab = useRecoilCallback(
    ({ set, snapshot }) =>
      async (tabIdToClose?: string, tabUrlData?: TabUrlData) => {
        const tabs = await snapshot.getPromise(patientTabsState);
        const lastPatientListUrlRelativePath = await snapshot.getPromise(
          currentPatientListUrRelativePath
        );
        const closedTabIndex = tabIdToClose
          ? tabs.findIndex(({ value }) => value === tabIdToClose)
          : tabs.length - 1;

        const closedTabId = tabs[closedTabIndex].value;
        const updatedTabs = tabs.filter((_, index) => index !== closedTabIndex);

        setTabs(updatedTabs);
        set(patientViewerDataStateFamily(closedTabId), null);

        if (tabUrlData) {
          selectTab({
            tabUrlData,
            updatedTabs,
            lastPatientListUrlRelativePath
          });
        } else if (closedTabId === activeTabIdRef.current) {
          const lastTab =
            closedTabIndex > 0 ? updatedTabs[closedTabIndex - 1] : null;
          lastTab
            ? selectTab({
                tabUrlData: { id: lastTab.value },
                lastPatientListUrlRelativePath
              })
            : selectTab({ lastPatientListUrlRelativePath });
        }
      },
    []
  );

  const HomeTab = {
    value: TabName.HOME,
    ishome: 'true',
    icon: <IconHome size={IconSizes.MEDIUM} />,
    onClick: () => selectTab()
  };

  const tabs = window.vizWidget ? [...currentTabs] : [HomeTab, ...currentTabs];
  return {
    tabs: tabs,
    isMaxTabsOpen: currentTabs.length >= MAX_OPEN_TABS,
    selectedTab: patientId ?? TabName.HOME,
    selectTab,
    closePatientTab
  };
};

export { usePatientTabs };
