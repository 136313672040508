import { localize } from '@viz/i18n';

import { StyledAcceptReferralDialog, StyledDialogContent } from './styles';

export type AcceptReferralProps = {
  open: boolean;
  onAccept: () => void;
  onClose: () => void;
};

const AcceptReferralDialog = ({
  open,
  onAccept,
  onClose
}: AcceptReferralProps): JSX.Element => {
  return (
    <StyledAcceptReferralDialog
      title={localize('acceptReferral')}
      open={open}
      onClose={onClose}
      withCloseIcon
      primaryButtonProps={{
        content: localize('accept'),
        onClick: () => {
          onAccept();
          onClose();
        }
      }}
      secondaryButtonProps={{
        content: localize('cancel'),
        onClick: onClose
      }}
    >
      <StyledDialogContent>
        {localize('acceptReferralQuestion')}
      </StyledDialogContent>
    </StyledAcceptReferralDialog>
  );
};

export default AcceptReferralDialog;
