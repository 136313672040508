import { Widget } from '@viz/design-system';
import { useRecoilValue } from 'recoil';

import { PatientFolder } from '../../../components';
import {
  isPatientFolderVisibleState,
  selectedPatientIdState
} from '../../../store';
import { LayoutSideMenu } from '../common';
import { LayoutProps } from '../types';
import {
  StyledGrid,
  StyledPatientFolderGrid,
  StyledGridContainer
} from './styles';

const PatientsLayout = ({ children }: LayoutProps): JSX.Element => {
  const selectedPatientId = useRecoilValue(selectedPatientIdState);
  const isPatientFolderVisible = useRecoilValue(isPatientFolderVisibleState);

  const showPatientFolder = selectedPatientId && isPatientFolderVisible;

  return (
    <>
      <LayoutSideMenu />
      <StyledGridContainer container $vizWidget={window.vizWidget ?? false}>
        <StyledGrid
          item
          xs={showPatientFolder ? 6 : 12}
          lg={showPatientFolder ? 8 : 12}
        >
          <Widget container fillSpace height="100%">
            {children}
          </Widget>
        </StyledGrid>
        {showPatientFolder && (
          <StyledPatientFolderGrid item xs={6} lg={4}>
            <PatientFolder
              patientId={selectedPatientId}
              isCloseable={true}
              testId="patients-layout-patient-folder"
            />
          </StyledPatientFolderGrid>
        )}
      </StyledGridContainer>
    </>
  );
};

export default PatientsLayout;
