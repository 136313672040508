import {
  ClientConfigTabStatus,
  patientDataModification,
  usePatient,
  useUpdatePatientStatus
} from '@viz/api';
import {
  EmptyContent,
  IconClose,
  IconPatient,
  IconSizes,
  Loading,
  Widget
} from '@viz/design-system';
import { PushNotificationType, VizFirebaseNotification } from '@viz/hooks';
import { localize } from '@viz/i18n';
import { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  AnalyticsEventName,
  PatientType,
  useAnalyticsEvent
} from '../../hooks';
import { useNotificationSubscriber } from '../../hooks/useNotificationSubscriber';
import { Subscriber } from '../../managers';
import {
  getTabHeader,
  isAllCasesDistributionListTab
} from '../../pages/Patients/utils';
import {
  isPatientFolderVisibleState,
  selectedDistributionListTabIdState,
  selectedPatientIdState,
  selectedPatientStatusInfoState
} from '../../store';
import { SelectedPatientStatusInfoState } from '../../store/types';
import ClinicalDetails from './ClinicalDetails';
import PatientInfo from './PatientInfo';
import { SharePatient } from './SharePatient/SharePatient';
import { StyledCloseButton, StyledLoadingPatient } from './styles';
import { isOneOfTabs } from './utils';

const HCM_TAB_HEADERS = ['HCM Suspected', 'HCM Follow-up'];

export interface PatientFolderProps {
  patientId?: string;
  testId?: string;
  isCloseable?: boolean;
  expandDesktopWindowMaxSize?: boolean;
}

const PatientFolder = (props: PatientFolderProps): JSX.Element => {
  const { patientId, isCloseable, testId, expandDesktopWindowMaxSize } = props;
  const setPatientFolderVisibleState = useSetRecoilState(
    isPatientFolderVisibleState
  );
  const setSelectedPatientId = useSetRecoilState(selectedPatientIdState);
  const { sendEvent } = useAnalyticsEvent();
  const analyticsLastPatientId = useRef<string>('');
  const {
    data: patient,
    isInitialLoading,
    refetch: refetchPatient
  } = usePatient(
    { enabled: !!patientId, select: patientDataModification },
    { patientId }
  );
  const selectedDistributionListTabId = useRecoilValue(
    selectedDistributionListTabIdState
  );

  const onStudyNotification: Subscriber<VizFirebaseNotification> = () => {
    if (patientId) {
      refetchPatient();
    }
  };

  const handleClose = () => {
    sendEvent(AnalyticsEventName.MINIMIZED_TO_PATIENT_LIST);
    setPatientFolderVisibleState(false);
    setSelectedPatientId('');
  };

  useNotificationSubscriber({
    subject: PushNotificationType.STUDY,
    onNotification: onStudyNotification
  });

  useEffect(() => {
    if (patient && analyticsLastPatientId.current !== patient.id) {
      sendEvent(AnalyticsEventName.PATIENT_OVERVIEW_VIEWED, {
        patient_id: patient.id,
        number_of_available_studies: patient.studies.length,
        di_patient_mrn: patient.diMedicalRecordNumber,
        di_patient_name: patient.diName,
        institution: patient.institutionName,
        ai_detections: patient.algoDetection.length
          ? patient.algoDetection[0]
          : null,
        type: PatientType.PATIENT
      });

      analyticsLastPatientId.current = patient.id;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient?.id]);

  const [selectedPatientStatusInfo, setSelectedPatientStatusInfo] =
    useRecoilState(selectedPatientStatusInfoState);

  const { mutate: updatePatientStatus } = useUpdatePatientStatus();

  const onChangePatientStatusInfo = (newStatus: ClientConfigTabStatus) => {
    if (
      !patientId ||
      !selectedPatientStatusInfo ||
      !selectedPatientStatusInfo.selectedTabId
    )
      return;

    sendEvent(AnalyticsEventName.PATIENT_STATUS_UPDATED, {
      current_status: selectedPatientStatusInfo.patientSelectedStatus,
      new_status: newStatus.value,
      patient_id: patientId,
      patient_list_type: getTabHeader(selectedDistributionListTabId),
      source_location: 'Patient Card'
    });

    updatePatientStatus(
      {
        patient_id: patientId,
        status_id: newStatus.id,
        distribution_list_spec_id: selectedPatientStatusInfo.selectedTabId
      },
      {
        onSuccess: () => {
          setSelectedPatientStatusInfo({
            /* NOTE: override selected status and isStale
              but set the rest (i.e selectedTabId and onChangePatientStatus) with original value */
            ...selectedPatientStatusInfo,
            patientSelectedStatus: newStatus.value,
            isStale: true
          });
        }
      }
    );
  };

  const isValidPatientStatusInfo = (
    info?: SelectedPatientStatusInfoState
  ): info is SelectedPatientStatusInfoState => {
    return info !== undefined && info.patientStatuses.length > 0;
  };

  useEffect(() => {
    window.vizWidget?.expandWindow({
      fullscreen: Boolean(expandDesktopWindowMaxSize)
    });

    return () => {
      window.vizWidget?.collapseWindow();
    };
  }, [expandDesktopWindowMaxSize]);

  return (
    <Widget
      container
      direction="column"
      height="100%"
      padding={0}
      data-testid={testId}
    >
      {patient ? (
        <>
          {isCloseable && (
            <StyledCloseButton onClick={handleClose}>
              <IconClose
                testId="patients-layout-patient-folder-close-button"
                size={IconSizes.MEDIUM}
              />
            </StyledCloseButton>
          )}
          <PatientInfo
            patient={patient}
            patientStatuses={selectedPatientStatusInfo?.patientStatuses}
            patientSelectedStatus={
              selectedPatientStatusInfo?.patientSelectedStatus
            }
            onChangePatientStatus={onChangePatientStatusInfo}
            showPatientStatus={
              !isAllCasesDistributionListTab(selectedDistributionListTabId) &&
              isValidPatientStatusInfo(selectedPatientStatusInfo)
            }
          />
          {patient && <SharePatient patient={patient}></SharePatient>}
          <ClinicalDetails
            patient={patient}
            onChangeClinicalInfo={() => refetchPatient()}
            patientTabId={selectedPatientStatusInfo?.selectedTabId}
            enableEditClinicalInfo={
              selectedPatientStatusInfo?.selectedTabId
                ? isOneOfTabs(
                    selectedPatientStatusInfo?.selectedTabId,
                    HCM_TAB_HEADERS
                  )
                : false
            }
          />
        </>
      ) : isInitialLoading ? (
        <StyledLoadingPatient>
          <Loading size={IconSizes.X_LARGE} testId="patient-loader" />
        </StyledLoadingPatient>
      ) : (
        <EmptyContent
          title={localize('selectPatient')}
          icon={<IconPatient />}
        />
      )}
    </Widget>
  );
};

export { PatientFolder };
