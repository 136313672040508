import { useContactSupport } from '@viz/api';
import { Input, Spacer } from '@viz/design-system';
import { localize } from '@viz/i18n';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { AnalyticsEventName, useAnalyticsEvent } from '../../hooks';
import { isContactSupportDialogOpenState, toastStatusState } from '../../store';
import { StyledCounter, StyledDialog, StyledLabel } from './styles';

const TEXT_INPUT_ID = 'contact-support-message';
const MAX_LENGTH = 500;

const ContactSupportDialog = () => {
  const [message, setMessage] = useState('');

  const setToastStatus = useSetRecoilState(toastStatusState);
  const [isOpen, setIsOpen] = useRecoilState(isContactSupportDialogOpenState);

  const { sendEvent } = useAnalyticsEvent();

  useEffect(() => {
    if (isOpen) {
      sendEvent(AnalyticsEventName.CONTACT_SUPPORT_FORM_VIEWED);
    }
  }, [isOpen, sendEvent]);

  const closeDialog = () => {
    setIsOpen(false);
  };

  const { mutate: contactSupport } = useContactSupport({
    onSuccess: (data) => {
      if ('success' in data) {
        sendEvent(AnalyticsEventName.CONTACT_SUPPORT_FORM_SUBMITTED);

        closeDialog();

        setToastStatus({
          type: 'info',
          text: localize('contactSupportSuccess')
        });

        setMessage('');
      } else {
        setToastStatus({
          type: 'error',
          text: localize('contactSupportError')
        });
      }
    }
  });

  const handleSubmit = () => {
    contactSupport({ freeform_text: message });
  };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length > MAX_LENGTH) return;
    setMessage(event.target.value);
  };

  return (
    <StyledDialog
      data-testid="contact-support-dialog"
      open={isOpen}
      onClose={closeDialog}
      primaryButtonProps={{
        content: localize('submit'),
        disabled: message.length <= 0 || message.length > MAX_LENGTH,
        onClick: handleSubmit
      }}
      secondaryButtonProps={{
        content: localize('cancel'),
        onClick: closeDialog
      }}
      title={localize('contactSupport')}
      withCloseIcon
    >
      <form>
        <StyledLabel htmlFor={TEXT_INPUT_ID}>
          {localize('contactSupportFeedback')}
        </StyledLabel>
        <Spacer axis="vertical" size={4} />
        <Input
          id={TEXT_INPUT_ID}
          value={message}
          onChange={handleChange}
          minRows={8}
          maxRows={8}
          fullWidth
          multiline
        />
        <Spacer axis="vertical" size={1} />
        <StyledCounter hasReachedMax={message.length >= MAX_LENGTH}>
          {message.length}/{MAX_LENGTH}
        </StyledCounter>
      </form>
    </StyledDialog>
  );
};

export { ContactSupportDialog };
