import { Divider } from '@material-ui/core';
import { ServerPatient } from '@viz/api';
import { IconSizes, VizBorderLine, VizFail, VizPass } from '@viz/design-system';
import { localize, TranslationKey } from '@viz/i18n';

import { getDate } from '../utils';
import {
  StyledAlignedRightText,
  StyledCriteriaFlex1,
  StyledCriteriaFlex3,
  StyledCriteriaPassFail,
  StyledCriterion,
  StyledDateInfo,
  StyledGrayedText,
  StyledIconWrapper
} from './styles';

const STATUS_MAP = {
  BORDERLINE: {
    Icon: VizBorderLine,
    text: 'borderline' as TranslationKey
  },
  PASS: {
    Icon: VizPass,
    text: 'pass' as TranslationKey
  },
  FAIL: {
    Icon: VizFail,
    text: 'fail' as TranslationKey
  }
};

const CriteriaGroup = ({ fields }: ServerPatient.PreScreening): JSX.Element => {
  return (
    <div>
      {fields.map((criterion, i) => (
        <div key={i}>
          <StyledCriterion>
            <StyledCriteriaFlex3>
              <div>{criterion.fieldLabel}</div>
              {criterion.acquisitionMilliTs && (
                <StyledDateInfo>
                  {getDate(criterion.acquisitionMilliTs)}
                </StyledDateInfo>
              )}
            </StyledCriteriaFlex3>
            <StyledCriteriaFlex1>
              {criterion.fieldValue !== 'N/A' ? (
                <StyledAlignedRightText>{`${criterion.fieldValue} ${
                  criterion.acquisitionUnit ?? ''
                }`}</StyledAlignedRightText>
              ) : (
                <StyledGrayedText> No Data </StyledGrayedText>
              )}
            </StyledCriteriaFlex1>
            <StyledCriteriaPassFail>
              {criterion.criterionStatus && <StatusIcon {...criterion} />}
            </StyledCriteriaPassFail>
          </StyledCriterion>
          {fields.length > i + 1 && <Divider />}
        </div>
      ))}
    </div>
  );
};

const StatusIcon = ({
  criterionStatus
}: ServerPatient.PreScreeningAnswers): JSX.Element => {
  const { Icon, text } = STATUS_MAP[criterionStatus];

  return (
    <StyledIconWrapper>
      <Icon size={IconSizes.SMALL} />
      <span>{localize(text)}</span>
    </StyledIconWrapper>
  );
};

export default CriteriaGroup;
