import {
  AccessTokenData,
  ClientConfig,
  DocumentationResponseData,
  GetProfileData,
  LoginResponseData,
  ResetPasswordResponseData,
  UserAuthInfo,
  UserChatAccess
} from '@viz/api';
import {
  ServerUserPolicyData,
  SignPolicyServerResponse
} from '@viz/api/src/policies/types';
import { getCurrentTime } from '@viz/utils';
import { addMinutes } from 'date-fns';
import jwt from 'jwt-simple';

const CURRENT_TIME = getCurrentTime();

const FAKE_JWT_EXPIRATION = 1000 * 60 * 60;
const FAKE_JWT_SECRET = 'top_secret_secret';
const FAKE_JWT_PAYLOAD = {
  iat: CURRENT_TIME,
  nbf: CURRENT_TIME,
  jti: '1ca86c6d-9253-499a-ae57-c641d9042a4a',
  exp: CURRENT_TIME + FAKE_JWT_EXPIRATION,
  identity: 'f8e10a7b-1866-434c-b793-fcc37d46b47c',
  fresh: false,
  type: 'access'
};

export const FAKE_TOKEN = jwt.encode(FAKE_JWT_PAYLOAD, FAKE_JWT_SECRET);

export const FAKE_ENCRYPTION_KEY = 'fake-encryption-key';

export const getFakeAccessExpiration = (): number =>
  addMinutes(new Date(), 5).getTime() / 1000;

export const FAKE_CLIENT_CONFIG: ClientConfig = {
  allow_external_study_image_sharing: true,
  allowed_user_workspaces: [0, 1],
  biometric_fallback_to_passcode: false,
  default_user_workspace: 0,
  tabs: [
    {
      display: true,
      features: [],
      header: 'Neuro Cases',
      icon_url: null,
      id: 6,
      placeholder: {
        image: 'default_tab_placeholder_image',
        text: 'No Results',
        title: null
      },
      share_type: null,
      shareable: false,
      statuses: [],
      sorting_options: [],
      default_sorting: null,
      subtitle: 'Cases',
      title: 'Neuro',
      type: 2,
      status_as_menu_item: false
    },
    {
      display: true,
      features: [],
      header: 'LVO Suspected',
      icon_url: 'https://bucket/assets/LVO.svg',
      id: 1,
      placeholder: {
        image: 'default_tab_placeholder_image',
        text: 'No Results',
        title: null
      },
      share_type: null,
      shareable: false,
      statuses: [],
      filters: [
        {
          display_name: 'Date Range',
          name: 'date_range',
          multiple_selection: false,
          options: [
            { name: 'today', display_name: 'Today' },
            {
              name: 'yesterday long long long',
              display_name: 'Yesterday long long long'
            },
            { name: 'last_7', display_name: 'Last 7 days' },
            { name: 'last_30', display_name: 'Last 30 days' }
          ]
        }
      ],
      sorting_options: [],
      default_sorting: null,
      subtitle: 'Suspected',
      title: 'LVO',
      type: 1,
      status_as_menu_item: false
    },
    {
      display: true,
      features: [],
      header: 'ICH Suspected',
      icon_url: 'https://bucket/assets/ICH.svg',
      id: 2,
      placeholder: {
        image: 'default_tab_placeholder_image',
        text: 'No Results',
        title: null
      },
      share_type: null,
      shareable: false,
      statuses: [
        {
          id: 1,
          value: 'Active'
        },
        {
          id: 2,
          value: 'Done'
        }
      ],
      filters: [
        {
          display_name: 'Date Range',
          name: 'date_of_arrival',
          multiple_selection: false,
          options: [
            { name: 'today', display_name: 'Today' },
            { name: 'yesterday', display_name: 'Yesterday' },
            { name: 'last_7', display_name: 'Last 7 days' },
            { name: 'last_30', display_name: 'Last 30 days' },
            { name: 'date_range', display_name: 'Custom Range' }
          ]
        },
        {
          display_name: 'Institution',
          name: 'institution',
          multiple_selection: false,
          options: [
            { name: 'a', display_name: 'some really long insitution name ' },
            { name: 'b', display_name: 'MacDonalds' }
          ]
        }
      ],
      sorting_options: [
        { id: 1, value: 'Chronological' },
        { id: 2, value: 'Relevance' },
        { id: 3, value: 'Age' }
      ],
      default_sorting: 1,
      subtitle: 'Suspected',
      title: 'ICH',
      type: 1,
      status_as_menu_item: true
    }
  ],
  products: null,
  viewer_only: false,
  viewer_presets: {
    mip_presets: [],
    window_presets: []
  },
  inactivity_timeout_in_secs: 300,
  enable_novo_trial_state: false
};

export const FAKE_USER: GetProfileData = {
  able_to_see_phi: false,
  allowed_user_workspaces: [0, 1],
  email: 'dudu.lasry@viz.ai',
  name: 'Dudu Lasry',
  phone: '+972522899252',
  role_id: 'VIZ',
  user_uid: '05aceb2b-4fe7-4345-82fb-66def9e43fa0',
  username: 'dudu.lasry@viz.ai'
};

export const FAKE_RESET_PASSWORD_RESPONSE: ResetPasswordResponseData = {
  email: 'mock.user@viz.ai',
  phone_number: '**********123'
};

export const FAKE_DOCUMENTATION: DocumentationResponseData = [
  {
    docs: [
      {
        dropbox_url:
          'https://www.dropbox.com/sh/9jh1axl3soqi6px/AAChG7EcnaJQf2o6cdFtqMCIa/LVO?dl=0&preview=amalgamated+LVO+Users+Guide.pdf&subfolder_nav_tracking=1',
        format: 1,
        name: 'LVO User Guide',
        url: 'about/userguide?viz_product=lvo&client_version=None'
      },
      {
        dropbox_url:
          'https://www.dropbox.com/sh/9jh1axl3soqi6px/AABEaVWhGX858IDUyt4ZqODla/ICH?dl=0&preview=amalgamated+ICH+Users+Guide.pdf&subfolder_nav_tracking=1',
        format: 1,
        name: 'ICH User Guide',
        url: 'about/userguide?viz_product=ich&client_version=None'
      },
      {
        dropbox_url:
          'https://www.dropbox.com/sh/9jh1axl3soqi6px/AADCk65vr1k-ysaKery6-KeBa/CTP?dl=0&preview=amalgamated+Viz+CTP+lnstallation+and+Users+Guide.pdf&subfolder_nav_tracking=1',
        format: 1,
        name: 'CTP User Guide',
        url: 'about/userguide?viz_product=ctp&client_version=None'
      },
      {
        dropbox_url:
          'https://www.dropbox.com/sh/9jh1axl3soqi6px/AAB74mwIvFBh6DsuFf3Sakksa/VIEW?dl=0&preview=Amalgamated+Viz+VIEW+Mobile+Application+Users+Guide.pdf&subfolder_nav_tracking=1',
        format: 1,
        name: 'View User Guide',
        url: 'about/userguide?viz_product=view&client_version=None'
      }
    ],
    title: 'INSTRUCTIONS FOR USE'
  },
  {
    docs: [
      {
        dropbox_url: '',
        format: 0,
        name: 'LVO About / EULA',
        url: 'about/legal?viz_product=lvo&client_version=None'
      },
      {
        dropbox_url: '',
        format: 0,
        name: 'ICH About / EULA',
        url: 'about/legal?viz_product=ich&client_version=None'
      },
      {
        dropbox_url: '',
        format: 0,
        name: 'CTP About / EULA',
        url: 'about/legal?viz_product=ctp&client_version=None'
      },
      {
        dropbox_url: '',
        format: 0,
        name: 'View About / EULA',
        url: 'about/legal?viz_product=view&client_version=None'
      }
    ],
    title: 'LEGAL'
  }
];

export const FAKE_USER_AUTH_INFO_NO_SSO: UserAuthInfo.Response = {
  0: {
    name: '',
    sso: { active: false, login_url: '', logout_url: '' },
    standard_auth: { active: true, login_url: '', logout_url: '' }
  }
};

export const FAKE_USER_CHAT_ACCSESS_RESPONSE: UserChatAccess = {
  api_key: 'api_key',
  patient_enabled: false,
  token: 'token'
};

export const FAKE_REFRESH_ACCESS_TOKEN_RESPONSE: AccessTokenData = {
  encryption_key: 'defaultKey',
  csrf_access_token: 'access_token',
  access_expiration: 2209039095
};

export const FAKE_LOGIN_RESPONSE: LoginResponseData = {
  ...FAKE_REFRESH_ACCESS_TOKEN_RESPONSE,
  user_uid: '',
  config: FAKE_CLIENT_CONFIG,
  access_token: FAKE_TOKEN,
  user_access: {
    web_access: true,
    admin_console_access: true
  },
  chat:FAKE_USER_CHAT_ACCSESS_RESPONSE
};

//expires in 2050. if you are reading this because the cookie expired, please pop open a bottle of champaigne for us! :)
export const FAKE_ACCESS_TOKEN_COOKIE = `access_token_cookie=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjI1MjQ2MDgwMDB9.j30vTH1AzxNDMP_TDZ1USRalnY-Gqij5TYB-ThlvBwA; expires=2524608000;`;

export const FAKE_USER_POLICIES_RESPONSE: ServerUserPolicyData.ResponseData = {
  has_documents_to_sign: false,
  policy_token: 'fake policy token',
  policy_urls: []
};

export const FAKE_USER_UNSIGNED_POLICIES_RESPONSE: ServerUserPolicyData.ResponseData =
  {
    has_documents_to_sign: true,
    policy_token: 'fake policy token',
    policy_urls: [
      {
        name: 'fake policy',
        title: 'policy',
        url: '/fake-policy-url'
      }
    ]
  };

export const FAKE_USER_SIGN_POLICY_RESPONSE: SignPolicyServerResponse = {
  success: 'true'
};
