import { localize } from '@viz/i18n';

import { AC_AUDIT_LOG_FF, getFFValue } from '../../../../ffUtils';

export const getUsersTableHeaders = () =>
  [
    { id: 'name', value: localize('name') },
    { id: 'email', value: localize('email') },
    { id: 'institution', value: localize('institution') },
    { id: 'position', value: localize('position') },
    { id: 'phone', value: localize('phone') },
    { id: 'isActive', value: localize('active') },
    { id: 'menuCell', value: '' }
  ].filter(({ id }) => {
    if (id === 'menuCell') {
      return getFFValue(AC_AUDIT_LOG_FF);
    }

    return true;
  });
