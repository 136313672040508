import { SidePanel } from '@viz/design-system';
import React from 'react';

export interface UserAuditLogPanelProps {
  opened: boolean;
  onClose: () => void;
}

const UserAuditLogPanel = ({ opened, onClose }: UserAuditLogPanelProps) => {
  return (
    <SidePanel open={opened} onClose={onClose}>
      <div>UserAuditLogPanel</div>
    </SidePanel>
  );
};

export default UserAuditLogPanel;
